import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import Select from "react-select"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { convertToRaw, ContentState, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useSelector, useDispatch } from "react-redux"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewReports as onAddNewReports,
  updateReports as onUpdateReports,
  //getReports as onGetReports,
  getReportsById as onGetReportsById,
} from "store/reports/actions"
import { getCompany as onGetCompany } from "store/company/actions"
import { getSustainGoal as onGetSustainGoals } from "store/common/actions"
import { uploadDocument as uploadDocument } from "store/document-upload/actions"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import classnames from "classnames"
import { getAccessToken } from "helpers/jwt-token-access/accessToken"
import axios from "axios"

const initialValues = {
  lang_id: null,
  filename: "",
  reporttype: "",
  // country: "",
  reportcode: "",
  reportdate: new Date(),
  tag: "",
  targetUser: "",
  access_group: "",
  tenure: "",
  isNew: true,
  tenure: "",
  fileurl: "",
  summaryDesc: "",
  impactDesc: "",
  //reportdate: '',
  companyid: "",
  addhome: null,
  id: null,
}

const validationSchema = Yup.object({
  filename: Yup.string().required("Please Enter Company Name"),
  reporttype: Yup.mixed()
    .required("Please select report type")
    .notOneOf(["-1"], "Please Select Report type"),
  // country: Yup.string().required("Please Select Country").notOneOf(['-1'], 'Please Select Country'),
  tag: Yup.string()
    .required("Please Select Chip")
    .notOneOf(["-1"], "Please Select Chip"),
  reportdate: Yup.date().required("Please Select Date"),
  targetUser: Yup.mixed()
    .required("Please Select Category")
    .notOneOf(["-1"], "Please Select Category"),
  access_group: Yup.array().when("targetUser", {
    is: targetUser =>
      !(
        targetUser?.length === 1 &&
        targetUser?.some(item => item["value"] === "basic")
      ), // Condition: apply validation only if eventType is 'online'
    then: Yup.array()
      .required("Please select user access level")
      .notOneOf(["-1"], "Please Select user access level"),
    otherwise: Yup.array(), // No validation rule when condition is not met
  }),
  // access_group: Yup.array().required("Please select user access level").notOneOf(['-1'], 'Please Select user access level'),
  tenure: Yup.string().required("Please Enter Tenure"),
  //uploaddocument: Yup.mixed().required('Please upload document'),
  // summaryDesc: Yup.string().required("Please Enter Summary Description"),
  // impactDesc: Yup.string().required("Please Enter Impact Description"),
  // sdgDesc: Yup.string().required("Please Enter Sustanable development goals Description"),
  // value: Yup.string().required("Please Enter Value"),
  // type: Yup.string().required("Please Select Type"),
  // targetUser: Yup.string().required("Please Select Target User"),
  // sectiondata: Yup.string().required("Please Select Section Data"),
  // sustainarr: Yup.string().required("Please Select Sustainability"),
  //reportdate: '',
  companyid: Yup.mixed()
    .required("Please Select Company")
    .notOneOf(["-1"], "Please Select Company"),
  addhome: Yup.number().required("Please select add to home"),
})

const AddReports = () => {
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(location.search)
  const language = JSON.parse(localStorage.getItem("language"))
  const reportId = searchParams.get("reportcode")
  const companyDetailsId = searchParams.get("companyId")
  const inpSummaryRow = [
    { sectionname: "summary", key: "", value: "", lang_id: language[1].id },
  ]
  const inpImpactRow = [
    { sectionname: "impact", key: "", value: "", lang_id: language[1].id },
  ]
  const inpSummaryRow3 = [
    { sectionname: "summary", key: "", value: "", lang_id: language[0].id },
  ]
  const inpImpactRow3 = [
    { sectionname: "impact", key: "", value: "", lang_id: language[0].id },
  ]
  const history = useHistory()
  // const [startDate, setstartDate] = useState(new Date());
  const [reportdate, setreportdate] = useState(new Date())
  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles3, setselectedFiles3] = useState([])
  const [selectedMulti, setselectedMulti] = useState(null)
  const [impactInputFields, setImpactInputFields] = useState([])
  const [summaryInputFields, setSummaryInputFields] = useState([])
  const [impactInputFields3, setImpactInputFields3] = useState([])
  const [summaryInputFields3, setSummaryInputFields3] = useState([])
  const [basicUserFlag, setBasicUserFlag] = useState(false)
  const [reportList, setReportList] = useState([])

  const {
    reportsById,
    sustainGoals,
    error,
    addUpdateSuccess,
    deleteSuccess,
    document,
  } = useSelector(state => ({
    reportsById: state.Reports.reportsById,
    sustainGoals: state.Common.sustainGoals,
    error: state.Reports.error,
    addUpdateSuccess: state.Reports.addUpdateSuccess,
    deleteSuccess: state.Reports.deleteSuccess,
    document: state.DocumentUpload.document,
  }))
  const { companies, error1 } = useSelector(state => ({
    companies: state.Company.company,
    error1: state.Company.error,
  }))
  // console.log(companies)
  const optionCountry = [
    { value: "AF", label: "Afghanistan" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Algeria" },
    { value: "AS", label: "American Samoa" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarctica" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaijan" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Bahrain" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Belarus" },
    { value: "BE", label: "Belgium" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BT", label: "Bhutan" },
    { value: "BO", label: "Bolivia" },
    { value: "BW", label: "Botswana" },
    { value: "BV", label: "Bouvet Island" },
    { value: "BR", label: "Brazil" },
    { value: "BN", label: "Brunei Darussalam" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "KH", label: "Cambodia" },
    { value: "CM", label: "Cameroon" },
    { value: "CA", label: "Canada" },
    { value: "CV", label: "Cape Verde" },
    { value: "KY", label: "Cayman Islands" },
    { value: "CF", label: "Central African Republic" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Christmas Island" },
    { value: "CC", label: "Cocos (Keeling) Islands" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoros" },
    { value: "CG", label: "Congo" },
    { value: "CK", label: "Cook Islands" },
    { value: "CR", label: "Costa Rica" },
    { value: "CI", label: "Cote d&#39;Ivoire" },
    { value: "HR", label: "Croatia (Hrvatska)" },
    { value: "CU", label: "Cuba" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Czech Republic" },
    { value: "DK", label: "Denmark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominican Republic" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egypt" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Equatorial Guinea" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "ET", label: "Ethiopia" },
    { value: "FK", label: "Falkland Islands (Malvinas)" },
    { value: "FO", label: "Faroe Islands" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finland" },
    { value: "FR", label: "France" },
    { value: "GF", label: "French Guiana" },
    { value: "PF", label: "French Polynesia" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Germany" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Greece" },
    { value: "GL", label: "Greenland" },
    { value: "GD", label: "Grenada" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haiti" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hungary" },
    { value: "IS", label: "Iceland" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IQ", label: "Iraq" },
    { value: "IE", label: "Ireland" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italy" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japan" },
    { value: "JO", label: "Jordan" },
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "KR", label: "Korea, Republic of" },
    { value: "KW", label: "Kuwait" },
    { value: "KG", label: "Kyrgyzstan" },
    { value: "LV", label: "Latvia" },
    { value: "LB", label: "Lebanon" },
    { value: "LS", label: "Lesotho" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libyan Arab Jamahiriya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "MO", label: "Macau" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malaysia" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MQ", label: "Martinique" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexico" },
    { value: "MD", label: "Moldova, Republic of" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolia" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Morocco" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Myanmar" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Netherlands" },
    { value: "AN", label: "Netherlands Antilles" },
    { value: "NC", label: "New Caledonia" },
    { value: "NZ", label: "New Zealand" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Niger" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk Island" },
    { value: "MP", label: "Northern Mariana Islands" },
    { value: "NO", label: "Norway" },
    { value: "OM", label: "Oman" },
    { value: "PW", label: "Palau" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua New Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PH", label: "Philippines" },
    { value: "PN", label: "Pitcairn" },
    { value: "PL", label: "Poland" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Qatar" },
    { value: "RE", label: "Reunion" },
    { value: "RO", label: "Romania" },
    { value: "RU", label: "Russian Federation" },
    { value: "RW", label: "Rwanda" },
    { value: "KN", label: "Saint Kitts and Nevis" },
    { value: "LC", label: "Saint LUCIA" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome and Principe" },
    { value: "SA", label: "Saudi Arabia" },
    { value: "SN", label: "Senegal" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapore" },
    { value: "SK", label: "Slovakia (Slovak Republic)" },
    { value: "SI", label: "Slovenia" },
    { value: "SB", label: "Solomon Islands" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "South Africa" },
    { value: "ES", label: "Spain" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SH", label: "St. Helena" },
    { value: "PM", label: "St. Pierre and Miquelon" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Suriname" },
    { value: "SZ", label: "Swaziland" },
    { value: "SE", label: "Sweden" },
    { value: "CH", label: "Switzerland" },
    { value: "SY", label: "Syrian Arab Republic" },
    { value: "TW", label: "Taiwan, Province of China" },
    { value: "TJ", label: "Tajikistan" },
    { value: "TZ", label: "Tanzania, United Republic of" },
    { value: "TH", label: "Thailand" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad and Tobago" },
    { value: "TN", label: "Tunisia" },
    { value: "TR", label: "Turkey" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TC", label: "Turks and Caicos Islands" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukraine" },
    { value: "AE", label: "United Arab Emirates" },
    { value: "GB", label: "United Kingdom" },
    { value: "US", label: "United States" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Viet Nam" },
    { value: "VG", label: "Virgin Islands (British)" },
    { value: "VI", label: "Virgin Islands (U.S.)" },
    { value: "WF", label: "Wallis and Futuna Islands" },
    { value: "EH", label: "Western Sahara" },
    { value: "YE", label: "Yemen" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" },
  ]
  const optionGroup = [
    { label: "Life on Land", value: "Life on Land" },
    { label: "No Poverty", value: "No Poverty" },
    {
      label: "Decent Work and Economic Growth",
      value: "Decent Work and Economic Growth",
    },
    {
      label: "Industry Innovation and Infrastructure",
      value: "Industry Innovation and Infrastructure",
    },
    { label: "Climate Action", value: "Climate Action" },
    { label: "Gender Equality", value: "Gender Equality" },
    { label: "Partnership for the goals", value: "Partnership for the goals" },
    {
      label: "Clean water and sanitation",
      value: "Clean water and sanitation",
    },
    {
      label: "Affordable and clean energy",
      value: "Affordable and clean energy",
    },
    { label: "Quality Education", value: "Quality Education" },
    {
      label: "Good health and well being",
      value: "Good health and well being",
    },
    {
      label: "Sustainable cities and communities",
      value: "Sustainable cities and communities",
    },
    {
      label: "Peace, Justice and strong institutions",
      value: "Peace, Justice and strong institutions",
    },
    { label: "Zero Hunger", value: "Zero Hunger" },
    { label: "Reduced Inequality", value: "Reduced Inequality" },
    {
      label: "Responsible Production and Consumption",
      value: "Responsible Production and Consumption",
    },
    { label: "Life Below Water", value: "Life Below Water" },
  ]

  const [activeTab, setActiveTab] = useState(language[0].id)
  useEffect(() => {
    if (error?.status === 401) history.push("/login")
    else if (addUpdateSuccess) {
      toast.success(`Reports ${isEdit ? "updated" : "added"} successfully`, {
        autoClose: 2000,
      })
      validation.resetForm()
      history.push("/templates")
    } else if (
      !addUpdateSuccess &&
      addUpdateSuccess !== null &&
      addUpdateSuccess !== undefined &&
      addUpdateSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
  }, [error, addUpdateSuccess])

  useEffect(() => {
    if (reportId !== null && reportId !== undefined) {
      //call api to get company data;
      let reportObj = {
        reportcode: reportId,
        lang_id: activeTab === 1 ? language[1].id : language[0].id,
      }
      axios
        .get(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/report?reportcode=" +
            reportObj.reportcode +
            "&lang_id=" +
            reportObj.lang_id,
          { headers: { Authorization: getAccessToken() } }
        )
        .then(res => {
          setReportList(res.data.data)
        })
      // dispatch(onGetReportsById(reportId))
    }
  }, [activeTab])

  useEffect(() => {
    // console.log('report', reportsById)
    if (reportList.length > 0 && reportId !== null) {
      setSelectedReports(reportList[0])
      setIsEdit(true)
      reportList[0]?.lang_id === 1
        ? assignInitialValues(reportList[0])
        : assignInitialValues3(reportList[0])
    }
    if (companies.length <= 0) dispatch(onGetCompany())
  }, [reportList])

  useEffect(() => {
    if (
      companies.length >= 0 &&
      companyDetailsId !== undefined &&
      companyDetailsId !== null &&
      companyDetailsId !== ""
    ) {
      validation.setFieldValue("companyid", companyDetailsId)
    }
  }, [companyDetailsId, companies])

  const assignInitialValues = reports => {
    validation.setFieldValue("lang_id", language[1].id)
    // setActiveTab(reports?.lang_id)
    validation.setFieldValue("filename", reports?.filename)
    validation.setFieldValue("reporttype", reports?.reporttype)
    // validation.setFieldValue("country", reports?.Company?.country);
    validation.setFieldValue("reportdate", new Date(reports?.reportdate))
    //validation.setFieldValue("description", reports?.description || 'Test');
    validation.setFieldValue("tag", reports?.tag)
    validation.setFieldValue("isNew", false)
    validation.setFieldValue("targetUser", reports.targetUser)
    validation.setFieldValue("tenure", reports.tenure)
    validation.setFieldValue("fileurl", reports.fileurl)
    validation.setFieldValue("id", reports.id)
    validation.setFieldValue("addhome", Number(reports.addhome))
    companyDetailsId
      ? validation.setFieldValue("companyid", companyDetailsId)
      : validation.setFieldValue("companyid", reports.companyid)

    // access group prefill
    if (reports.access_group !== "" && reports.access_group !== null) {
      let tempArr1 = reports.access_group.split(",")
      let tempaccess_groupArr = []
      tempArr1.forEach(element => {
        let tempObj = { label: element, value: element }
        tempaccess_groupArr.push(tempObj)
      })
      if (tempaccess_groupArr.length > 0)
        validation.setFieldValue("access_group", tempaccess_groupArr)
    }

    // target user prefill
    if (reports.targetUser !== "" && reports.access_group !== null) {
      let tempArr = reports.targetUser.split(",")
      let tempTargetUserArr = []
      tempArr.forEach(element => {
        let objIndex = findIndexByPropertyValue(optionGroup1, "value", element)
        tempTargetUserArr.push(optionGroup1[objIndex])
      })
      if (tempTargetUserArr.length > 0) {
        validation.setFieldValue("targetUser", tempTargetUserArr)
        if (
          tempTargetUserArr.length === 1 &&
          tempTargetUserArr.some(item => item["value"] === "basic")
        ) {
          validation.setFieldValue("access_group", "")
          setBasicUserFlag(true)
        } else {
          setBasicUserFlag(false)
        }
      }
    }

    getImageFileFromUrl(reports?.fileurl, reports?.filename).then(file => {
      if (file) {
        let temp = [
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          }),
        ]
        setselectedFiles(temp)
      }
    })
    validation.setFieldValue("fileurl", reports?.fileurl)

    let tempSummary = [],
      tempImpact = []
    reports?.sectiondata.forEach(item => {
      if (item.key === "description") {
        item.sectionname === "summary"
          ? setSummaryEditorState(prevState => ({
              ...prevState,
              editorValue: getContentBlock(item.value),
              id: item.id,
              reportsid: reports?.id,
              value: item?.value,
              companyid: companyDetailsId
                ? companyDetailsId
                : reports.companyid,
              lang_id: language[1].id,
            }))
          : item.sectionname === "impact"
          ? setImpactEditorState(prevState => ({
              ...prevState,
              editorValue: getContentBlock(item.value),
              id: item.id,
              reportsid: reports?.id,
              value: item?.value,
              companyid: companyDetailsId
                ? companyDetailsId
                : reports.companyid,
              lang_id: language[1].id,
            }))
          : ""
        item.sectionname === "summary"
          ? validation.setFieldValue("summaryDesc", item?.value)
          : item.sectionname === "impact"
          ? validation.setFieldValue("impactDesc", item?.value)
          : ""
      } else {
        let tempSecSummary = { ...inpSummaryRow[0] }
        let tempSecImpact = { ...inpImpactRow[0] }
        if (item.sectionname === "summary") {
          tempSecSummary.key = item.key
          tempSecSummary.value = item.value
          tempSecSummary["reportsid"] = reports.id
          tempSecSummary["id"] = item.id
          tempSecSummary["companyid"] = companyDetailsId
            ? companyDetailsId
            : reports.companyid
          tempSummary.push(tempSecSummary)
        } else if (item.sectionname === "impact") {
          tempSecImpact.key = item.key
          tempSecImpact.value = item.value
          tempSecImpact["reportsid"] = reports.id
          tempSecImpact["id"] = item.id
          tempSecSummary["companyid"] = companyDetailsId
            ? companyDetailsId
            : reports.companyid
          tempImpact.push(tempSecImpact)
        }
      }
    })
    setSummaryInputFields(tempSummary)
    setImpactInputFields(tempImpact)

    //   let tempSdgOptions = [];
    //   if (reports?.sustainarr?.length > 0) {
    //     reports?.sustainarr.forEach(item => {
    //       tempSdgOptions.push({
    //         label: item.SustainGoal.value,
    //         value: item.sustaingoalid,
    //         id: item.id
    //       })
    //     });
    //   }
    //   validation.setFieldValue("sdgOptions", tempSdgOptions);
  }

  const assignInitialValues3 = reports => {
    validation3.setFieldValue("lang_id", language[0].id)
    // setActiveTab(reports?.lang_id)
    validation3.setFieldValue("filename", reports?.filename)
    validation3.setFieldValue("reporttype", reports?.reporttype)
    // validation3.setFieldValue("country", reports?.Company?.country);
    validation3.setFieldValue("reportdate", new Date(reports?.reportdate))
    //validation3.setFieldValue("description", reports?.description || 'Test');
    validation3.setFieldValue("tag", reports?.tag)
    validation3.setFieldValue("isNew", false)
    validation3.setFieldValue("targetUser", reports.targetUser)
    validation3.setFieldValue("tenure", reports.tenure)
    validation3.setFieldValue("fileurl", reports.fileurl)
    validation3.setFieldValue("id", reports.id)
    validation3.setFieldValue("addhome", Number(reports.addhome))
    companyDetailsId
      ? validation3.setFieldValue("companyid", companyDetailsId)
      : validation3.setFieldValue("companyid", reports.companyid)

    // access group prefill
    if (reports.access_group !== "" && reports.access_group !== null) {
      let tempArr1 = reports.access_group.split(",")
      let tempaccess_groupArr = []
      tempArr1.forEach(element => {
        let tempObj = { label: element, value: element }
        tempaccess_groupArr.push(tempObj)
      })
      if (tempaccess_groupArr.length > 0)
        validation3.setFieldValue("access_group", tempaccess_groupArr)
    }

    // target user prefill
    if (reports.targetUser !== "" && reports.access_group !== null) {
      let tempArr = reports.targetUser.split(",")
      let tempTargetUserArr = []
      tempArr.forEach(element => {
        let objIndex = findIndexByPropertyValue(optionGroup1, "value", element)
        tempTargetUserArr.push(optionGroup1[objIndex])
      })
      if (tempTargetUserArr.length > 0) {
        validation3.setFieldValue("targetUser", tempTargetUserArr)
        if (
          tempTargetUserArr.length === 1 &&
          tempTargetUserArr.some(item => item["value"] === "basic")
        ) {
          validation3.setFieldValue("access_group", "")
          setBasicUserFlag(true)
        } else {
          setBasicUserFlag(false)
        }
      }
    }

    getImageFileFromUrl(reports?.fileurl, reports?.filename).then(file => {
      if (file) {
        let temp = [
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          }),
        ]
        setselectedFiles3(temp)
      }
    })
    validation3.setFieldValue("fileurl", reports?.fileurl)

    let tempSummary = [],
      tempImpact = []
    reports?.sectiondata.forEach(item => {
      if (item.key === "description") {
        item.sectionname === "summary"
          ? setSummaryEditorState3(prevState => ({
              ...prevState,
              editorValue: getContentBlock(item.value),
              id: item.id,
              reportsid: reports?.id,
              value: item?.value,
              companyid: companyDetailsId
                ? companyDetailsId
                : reports.companyid,
              lang_id: language[0].id,
            }))
          : item.sectionname === "impact"
          ? setImpactEditorState3(prevState => ({
              ...prevState,
              editorValue: getContentBlock(item.value),
              id: item.id,
              reportsid: reports?.id,
              value: item?.value,
              companyid: companyDetailsId
                ? companyDetailsId
                : reports.companyid,
              lang_id: language[0].id,
            }))
          : ""
        item.sectionname === "summary"
          ? validation3.setFieldValue("summaryDesc", item?.value)
          : item.sectionname === "impact"
          ? validation3.setFieldValue("impactDesc", item?.value)
          : ""
      } else {
        let tempSecSummary = { ...inpSummaryRow3[0] }
        let tempSecImpact = { ...inpImpactRow3[0] }
        if (item.sectionname === "summary") {
          tempSecSummary.key = item.key
          tempSecSummary.value = item.value
          tempSecSummary["reportsid"] = reports.id
          tempSecSummary["id"] = item.id
          tempSecSummary["companyid"] = companyDetailsId
            ? companyDetailsId
            : reports.companyid
          tempSummary.push(tempSecSummary)
        } else if (item.sectionname === "impact") {
          tempSecImpact.key = item.key
          tempSecImpact.value = item.value
          tempSecImpact["reportsid"] = reports.id
          tempSecImpact["id"] = item.id
          tempSecSummary["companyid"] = companyDetailsId
            ? companyDetailsId
            : reports.companyid
          tempImpact.push(tempSecImpact)
        }
      }
    })
    setSummaryInputFields3(tempSummary)
    setImpactInputFields3(tempImpact)

    //   let tempSdgOptions = [];
    //   if (reports?.sustainarr?.length > 0) {
    //     reports?.sustainarr.forEach(item => {
    //       tempSdgOptions.push({
    //         label: item.SustainGoal.value,
    //         value: item.sustaingoalid,
    //         id: item.id
    //       })
    //     });
    //   }
    //   validation.setFieldValue("sdgOptions", tempSdgOptions);
  }

  const findIndexByPropertyValue = (array, property, value) => {
    return array.findIndex(obj => obj[property] === value)
  }

  function handleAcceptedFiles(files) {
    dispatch(uploadDocument(files[0]))
    // validation.setFieldValue('fileurl', 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf');
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    activeTab === 1 ? setselectedFiles(files) : setselectedFiles3(files)
  }

  useEffect(() => {
    if (document !== undefined) {
      activeTab === 1
        ? validation.setFieldValue("fileurl", document?.fileurl)
        : validation3.setFieldValue("fileurl", document?.fileurl)
    }
  }, [document])

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const optionGroup1 = [
    { label: "Individual Investor", value: "individual_investor" },
    { label: "Advisor", value: "advisor" },
    { label: "Legal Representative", value: "legalrepresent" },
    // { label: "Basic", value: "basic" }
  ]
  const userlevelOption = [
    { label: "Intermediate", value: "intermediate" },
    { label: "Advanced", value: "advanced" },
  ]
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti)
  }
  //const startDateChange = date => {
  //setstartDate(date);
  //};

  const endDateChange = date => {
    setreportdate(date)
  }

  const inpRow = [{ name: "", file: "" }]
  const [inputFields, setinputFields] = useState(inpRow)

  function handleAddFields(fieldRef) {
    if (activeTab === 1) {
      if (fieldRef === "summary")
        setSummaryInputFields([...summaryInputFields, inpSummaryRow[0]])
      else if (fieldRef === "impact")
        setImpactInputFields([...impactInputFields, inpImpactRow[0]])
    } else {
      if (fieldRef === "summary3")
        setSummaryInputFields3([...summaryInputFields3, inpSummaryRow3[0]])
      else if (fieldRef === "impact3")
        setImpactInputFields3([...impactInputFields3, inpImpactRow3[0]])
    }
  }

  // Function for Remove Input Fields
  function handleRemoveFields(fieldRef, idx) {
    if (activeTab === 1) {
      if (fieldRef === "summary")
        setSummaryInputFields(removeItemByIndex(summaryInputFields, idx))
      else if (fieldRef === "impact")
        setImpactInputFields(removeItemByIndex(impactInputFields, idx))
    } else {
      if (fieldRef === "summary3")
        setSummaryInputFields3(removeItemByIndex(summaryInputFields3, idx))
      else if (fieldRef === "impact3")
        setImpactInputFields3(removeItemByIndex(impactInputFields3, idx))
    }
  }

  const removeItemByIndex = (array, indexToRemove) => {
    if (indexToRemove < 0 || indexToRemove >= array.length) {
      return array // Return the original array if the index is out of bounds
    }
    const newArray = [...array] // Create a shallow copy of the original array
    newArray.splice(indexToRemove, 1) // Remove one element at the specified index
    return newArray
  }

  const [sustainArray, setSustainArray] = useState([])
  useEffect(() => {
    // console.log('sustainGoals', sustainGoals);
    if (sustainGoals.length <= 0) {
      //call api to get company data;
      dispatch(onGetSustainGoals())
    } else {
      let temp = []
      sustainGoals.forEach(element => {
        temp.push({
          label: element.value,
          value: element.id,
        })
      })
      setSustainArray(temp)
    }
  }, [sustainGoals])

  const [isEdit, setIsEdit] = useState(false)
  const [selectedReports, setSelectedReports] = useState({})

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      console.log("values", values)
      let targetUserString = ""
      if (values["targetUser"] !== "" && values["targetUser"].length > 0) {
        values["targetUser"].forEach(element => {
          targetUserString +=
            targetUserString === "" ? element.value : `,${element.value}`
        })
      }
      let access_groupString = ""
      if (values["access_group"] !== "" && values["access_group"].length > 0) {
        values["access_group"].forEach(element => {
          access_groupString +=
            access_groupString === "" ? element.value : `,${element.value}`
        })
      }
      let payload = { ...values }
      payload.targetUser = targetUserString
      if (targetUserString === "basic") payload.access_group = null
      else payload.access_group = access_groupString

      console.log("values", values)
      let sectionData = []
      let tempSustainArr = []
      if (values["summaryDesc"] !== "") {
        sectionData.push(summaryEditorState)
        sectionData[0]["companyid"] = values.companyid
        delete sectionData[0].editorValue
      }
      if (values["impactDesc"] !== "") {
        sectionData.push(impactEditorState)
        sectionData[1]["companyid"] = values.companyid
        delete sectionData[1].editorValue
      }
      // if (values["sdgDesc"] !== "") {
      //   sectionData.push(sdgEditorState);
      // }

      if (summaryInputFields.length > 0) {
        summaryInputFields.map(item => {
          return (item["companyid"] = values.companyid)
        })
        console.log("summaryInputFields", summaryInputFields)
        let temp = sectionData.concat(summaryInputFields)
        sectionData = temp
      }
      if (impactInputFields.length > 0) {
        impactInputFields.map(item => {
          return (item["companyid"] = values.companyid)
        })
        let temp = sectionData.concat(impactInputFields)
        sectionData = temp
      }
      // if (values["sdgOptions"]?.length > 0) {
      //   values["sdgOptions"].forEach(element => {
      //     if(isEdit)
      //       tempSustainArr.push({"sustaingoalid": element.value, "reportsid": selectedReports.id, "id": `${element.id !== undefined ? element.id : null}`})
      //     else
      //       tempSustainArr.push({"sustaingoalid": element.value})
      //   })
      // }
      payload.sectiondata = sectionData
      payload.sustainarr = tempSustainArr
      delete payload.impactDesc
      delete payload.summaryDesc

      if (isEdit) {
        console.log("updateNewsPayload", payload)
        payload.reportcode = reportId
        if (reportList[0].lang_id !== activeTab) {
          payload.id = null
        } else {
          payload.id = reportList[0].id
        }
        // update company
        dispatch(onUpdateReports(payload))
        // history.push('/templates');
      } else {
        console.log("newNewsPayload", payload)
        // save new customer
        dispatch(onAddNewReports(payload))
        validation.resetForm()
        // history.push('/templates');
      }
    },
  })

  const validation3 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      console.log("values", values)
      let targetUserString = ""
      if (values["targetUser"] !== "" && values["targetUser"].length > 0) {
        values["targetUser"].forEach(element => {
          targetUserString +=
            targetUserString === "" ? element.value : `,${element.value}`
        })
      }
      let access_groupString = ""
      if (values["access_group"] !== "" && values["access_group"].length > 0) {
        values["access_group"].forEach(element => {
          access_groupString +=
            access_groupString === "" ? element.value : `,${element.value}`
        })
      }
      let payload = { ...values }
      payload.targetUser = targetUserString
      if (targetUserString === "basic") payload.access_group = null
      else payload.access_group = access_groupString

      console.log("values", values)
      let sectionData = []
      let tempSustainArr = []
      if (values["summaryDesc"] !== "") {
        sectionData.push(summaryEditorState3)
        sectionData[0]["companyid"] = values.companyid
        delete sectionData[0].editorValue
      }
      if (values["impactDesc"] !== "") {
        sectionData.push(impactEditorState3)
        sectionData[1]["companyid"] = values.companyid
        delete sectionData[1].editorValue
      }
      // if (values["sdgDesc"] !== "") {
      //   sectionData.push(sdgEditorState);
      // }

      if (summaryInputFields3.length > 0) {
        summaryInputFields3.map(item => {
          return (item["companyid"] = values.companyid)
        })
        console.log("summaryInputFields", summaryInputFields3)
        let temp = sectionData.concat(summaryInputFields3)
        sectionData = temp
      }
      if (impactInputFields3.length > 0) {
        impactInputFields3.map(item => {
          return (item["companyid"] = values.companyid)
        })
        let temp = sectionData.concat(impactInputFields3)
        sectionData = temp
      }
      // if (values["sdgOptions"]?.length > 0) {
      //   values["sdgOptions"].forEach(element => {
      //     if(isEdit)
      //       tempSustainArr.push({"sustaingoalid": element.value, "reportsid": selectedReports.id, "id": `${element.id !== undefined ? element.id : null}`})
      //     else
      //       tempSustainArr.push({"sustaingoalid": element.value})
      //   })
      // }
      payload.sectiondata = sectionData
      payload.sustainarr = tempSustainArr
      delete payload.impactDesc
      delete payload.summaryDesc

      if (isEdit) {
        console.log("updateNewsPayload", payload)
        // update company
        payload.reportcode = reportId
        if (reportList[0].lang_id !== activeTab) {
          payload.id = null
        } else {
          payload.id = reportList[0].id
        }
        dispatch(onUpdateReports(payload))
        // history.push('/templates');
      } else {
        console.log("newNewsPayload", payload)
        // save new customer
        dispatch(onAddNewReports(payload))
        validation3.resetForm()
        // history.push('/templates');
      }
    },
  })

  useEffect(() => {
    if (
      validation?.values["lang_id"] === undefined ||
      validation?.values["lang_id"] === null
    )
      validation.setFieldValue("lang_id", language[1].id)

    if (
      validation3?.values["lang_id"] === undefined ||
      validation3?.values["lang_id"] === null
    )
      validation3.setFieldValue("lang_id", language[0].id)
  }, [])

  // useEffect(() => {
  //   console.log("formik obj", validation)
  // })

  const [summaryEditorState, setSummaryEditorState] = useState({
    // id: null,
    // companyid: null,
    sectionname: "summary",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[1].id,
  })
  const [summaryEditorState3, setSummaryEditorState3] = useState({
    // id: null,
    // companyid: null,
    sectionname: "summary",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[0].id,
  })
  const [impactEditorState, setImpactEditorState] = useState({
    // id: null,
    // companyid: null,
    sectionname: "impact",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[1].id,
  })
  const [impactEditorState3, setImpactEditorState3] = useState({
    // id: null,
    // companyid: null,
    sectionname: "impact",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[0].id,
  })
  const [sdgEditorState, setSdgEditorState] = useState({
    // id: null,
    // companyid: null,
    sectionname: "sustainGoal",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[0].id,
  })
  const [sdgEditorState3, setSdgEditorState3] = useState({
    // id: null,
    // companyid: null,
    sectionname: "sustainGoal",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[0].id,
  })
  const handleEditorChange = (newEditorState, fieldName) => {
    const currentContent = convertToRaw(newEditorState.getCurrentContent())
    const isEmpty = currentContent?.blocks[0]?.text === ""
    // const html = draftToHtml(rawContentState);
    if (activeTab === 1) {
      validation.setFieldValue(
        fieldName,
        isEmpty ? "" : draftToHtml(currentContent)
      )
      validation.setFieldTouched(fieldName, true)

      fieldName === "summaryDesc"
        ? setSummaryEditorState(prevState => ({
            ...prevState,
            editorValue: newEditorState,
            value: isEmpty ? "" : draftToHtml(currentContent),
          }))
        : fieldName === "impactDesc"
        ? setImpactEditorState(prevState => ({
            ...prevState,
            editorValue: newEditorState,
            value: isEmpty ? "" : draftToHtml(currentContent),
          }))
        : setSdgEditorState(prevState => ({
            ...prevState,
            editorValue: newEditorState,
            value: isEmpty ? "" : draftToHtml(currentContent),
          }))
    } else {
      validation3.setFieldValue(
        fieldName,
        isEmpty ? "" : draftToHtml(currentContent)
      )
      validation3.setFieldTouched(fieldName, true)

      fieldName === "summaryDesc"
        ? setSummaryEditorState3(prevState => ({
            ...prevState,
            editorValue: newEditorState,
            value: isEmpty ? "" : draftToHtml(currentContent),
          }))
        : fieldName === "impactDesc"
        ? setImpactEditorState3(prevState => ({
            ...prevState,
            editorValue: newEditorState,
            value: isEmpty ? "" : draftToHtml(currentContent),
          }))
        : setSdgEditorState3(prevState => ({
            ...prevState,
            editorValue: newEditorState,
            value: isEmpty ? "" : draftToHtml(currentContent),
          }))
    }
  }

  const handleOnChange = (secRef, fieldRef, index, value) => {
    console.log(secRef, activeTab, "Handle on change")
    if (activeTab === 1) {
      let tempObj =
        secRef === "summary"
          ? summaryInputFields[index]
          : impactInputFields[index]
      if (fieldRef === "key") {
        tempObj.key = value
      } else if (fieldRef === "value") {
        tempObj.value = value
      }

      if (secRef === "summary") {
        setSummaryInputFields(prevItems => {
          const newArray = [...prevItems]
          newArray[index] = tempObj
          return newArray
        })
      } else if (secRef === "impact") {
        setImpactInputFields(prevItems => {
          const newArray = [...prevItems]
          newArray[index] = tempObj
          return newArray
        })
      }
    } else {
      let tempObj =
        secRef === "summary3"
          ? summaryInputFields3[index]
          : impactInputFields3[index]
      console.log(tempObj)
      if (fieldRef === "key") {
        tempObj.key = value
      } else if (fieldRef === "value") {
        tempObj.value = value
      }

      if (secRef === "summary3") {
        setSummaryInputFields3(prevItems => {
          const newArray = [...prevItems]
          newArray[index] = tempObj
          return newArray
        })
      } else if (secRef === "impact3") {
        setImpactInputFields3(prevItems => {
          const newArray = [...prevItems]
          newArray[index] = tempObj
          return newArray
        })
      }
    }
  }

  async function getImageFileFromUrl(imageUrl, fileName) {
    try {
      // Fetch image data
      const response = await fetch(imageUrl)
      const blob = await response.blob()

      // Create a File object from the blob
      const file = new File([blob], fileName, { type: blob.type })

      return file
    } catch (error) {
      console.error("Error fetching image:", error)
      return null
    }
  }

  const getContentBlock = htmlContent => {
    const contentBlock = htmlToDraft(htmlContent)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const newEditorState = EditorState.createWithContent(contentState)
      return newEditorState
    }
    return ""
  }

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          {isEdit ? (
            <Breadcrumbs title="Templates" breadcrumbItem="Edit Reports" />
          ) : (
            <Breadcrumbs title="Templates" breadcrumbItem="Add Reports" />
          )}
          {/* <Row>
                <Col xs="12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between float-right">
                        <Button
                        color="primary"
                        className="btn-rounded "
                        >
                        Add Reports
                        </Button>
                    </div>    
                </Col>
            </Row> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Add Reports</CardTitle> */}
                  <Nav pills className="navtab-bg nav-justified mb-2">
                    {language.map((option, index) => (
                      <NavItem key={index} value={option.id}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === option.id,
                          })}
                          onClick={() => {
                            toggle(option.id)
                          }}
                        >
                          {option.language_name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId={1} key={1} value={1}>
                      <Form onSubmit={validation.handleSubmit}>
                        {/* <FormGroup className="select2-container mb-4" row>
                                        <Label className="col-form-label col-lg-12">
                                            Language
                                        </Label>
                                        <Col md="10">
                                            <select
                                            className={`form-control select2`}
                                            title="Language"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            name="lang_id"
                                            value={validation.values.lang_id || ""}
                                            placeholder="select language"
                                            >
                                            {language.map((option, index) => (
                                                <option key={index} value={option.id}>
                                                {option.language_name}
                                                </option>
                                            ))}
                                            </select>
                                        </Col>
                                    </FormGroup> */}
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="title"
                            className="col-form-label col-lg-12"
                          >
                            Title
                          </Label>
                          <Col lg="10">
                            <Input
                              id="filename"
                              name="filename"
                              type="text"
                              className="form-control"
                              placeholder="Enter Report Name..."
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.filename}
                              invalid={
                                validation.touched.filename &&
                                validation.errors.filename
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.filename &&
                            validation.errors.filename ? (
                              <FormFeedback type="invalid">
                                {validation.errors.filename}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Show on Home Page
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation.touched.addhome &&
                                validation.errors.addhome
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Highlights"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="addhome"
                              value={validation.values.addhome}
                              aria-invalid={
                                validation.touched.addhome &&
                                validation.errors.addhome
                                  ? true
                                  : false
                              }
                              placeholder="select Highlight type"
                            >
                              <option value="-1">Select</option>
                              <option value={1}>Yes</option>
                              <option value={0}>No</option>
                            </select>
                            {validation.touched.addhome &&
                            validation.errors.addhome ? (
                              <FormFeedback type="invalid">
                                {validation.errors.addhome}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Report Type
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation.touched.reporttype &&
                                validation.errors.reporttype
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Report Type"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="reporttype"
                              value={validation.values.reporttype || ""}
                              aria-invalid={
                                validation.touched.reporttype &&
                                validation.errors.reporttype
                                  ? true
                                  : false
                              }
                              placeholder="select Report Type"
                            >
                              <option value="0">Select</option>
                              <option value="Annual">Annual</option>
                              <option value="Quaterly">Quaterly</option>
                              <option value="Comapny">Comapny</option>
                              <option value="General">General</option>
                            </select>
                            {validation.touched.reporttype &&
                            validation.errors.reporttype ? (
                              <FormFeedback type="invalid">
                                {validation.errors.reporttype}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Company
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation.touched.companyid &&
                                validation.errors.companyid
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Report Type"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="companyid"
                              value={validation.values.companyid || ""}
                              aria-invalid={
                                validation.touched.companyid &&
                                validation.errors.companyid
                                  ? true
                                  : false
                              }
                              placeholder="select Report Type"
                              disabled={companyDetailsId ? true : false}
                            >
                              {/* <option value="0">Select</option>
                                          <option value="17">Comapny 1</option>
                                          <option value="16">Comapny 2</option>
                                          <option value="15">Comapny 3</option>
                                          <option value="14">Comapny 4</option> */}
                              <option value="-1">Select Company</option>
                              {companies.map((option, index) => (
                                <option key={index} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            {validation.touched.companyid &&
                            validation.errors.companyid ? (
                              <FormFeedback type="invalid">
                                {validation.errors.companyid}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        {/* <FormGroup className="select2-container mb-4" row>
                                      <Label className="col-form-label col-lg-12">
                                          Country
                                      </Label>
                                      <Col md="10">
                                          <select
                                          className={`form-control select2 ${validation.touched.country && validation.errors.country ? 'is-invalid': ''}`}
                                          title="Country"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          name="country"
                                          value={validation.values.country || ""}
                                          aria-invalid={
                                              validation.touched.country && validation.errors.country
                                              ? true
                                              : false
                                          }
                                          placeholder="select country"
                                          >
                                          <option value="-1">select country</option>
                                          {optionCountry.map((option, index) => (
                                              <option key={index} value={option.value}>{option.label}</option>
                                          ))}
                                          </select>
                                          {validation.touched.country &&
                                          validation.errors.country ? (
                                          <FormFeedback type="invalid">
                                              {validation.errors.country}
                                          </FormFeedback>
                                          ) : null}
                                      </Col>
                                  </FormGroup> */}
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Chips
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation.touched.tag && validation.errors.tag
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Chips"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="tag"
                              value={validation.values.tag || ""}
                              aria-invalid={
                                validation.touched.tag && validation.errors.tag
                                  ? true
                                  : false
                              }
                              placeholder="select Chips"
                            >
                              <option value="0">Select</option>
                              <option value="Leading">Leading</option>
                              <option value="Highlight">Highlight</option>
                              <option value="Latest">Latest</option>
                              <option value="In Focus">In Focus</option>
                              <option value="Trending">Trending</option>
                            </select>
                            {validation.touched.tag && validation.errors.tag ? (
                              <FormFeedback type="invalid">
                                {validation.errors.tag}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="title"
                            className="col-form-label col-lg-12"
                          >
                            Tenure
                          </Label>
                          <Col lg="10">
                            <Input
                              id="tenure"
                              name="tenure"
                              type="text"
                              className="form-control"
                              placeholder="Enter tenure"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.tenure || ""}
                              invalid={
                                validation.touched.tenure &&
                                validation.errors.tenure
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.tenure &&
                            validation.errors.tenure ? (
                              <FormFeedback type="invalid">
                                {validation.errors.tenure}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Report Date
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col className="pl-0">
                                {/* {" "}
                                                  <DatePicker
                                                  className="form-control"
                                                  selected={reportdate}
                                                  id="reportdate"
                                                  name="reportdate"
                                                  placeholder="Please Select Report Date"
                                                  //onChange={validation.handleChange}
                                                  //onChange={endDateChange}
                                                  onChange={(reportdate) => {
                                                      validation.setFieldValue('reportdate', new Date(reportdate))
                                                  }}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.reportdate || ""}
                                                  invalid={
                                                      validation.touched.reportdate && validation.errors.reportdate
                                                      ? true
                                                      : false
                                                  }    
                                                  /> */}
                                <Input
                                  className="form-control"
                                  type="date"
                                  defaultValue="2019-08-19"
                                  id="reportdate"
                                  name="reportdate"
                                  onChange={event => {
                                    validation.setFieldValue(
                                      "reportdate",
                                      new Date(event.target.value)
                                    )
                                  }}
                                  selected={validation.values.reportdate}
                                  invalid={
                                    validation.touched.reportdate &&
                                    validation.errors.reportdate
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                              {validation.touched.reportdate &&
                              validation.errors.reportdate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.reportdate}
                                </FormFeedback>
                              ) : null}
                            </Row>
                          </Col>
                        </FormGroup>
                        {/* <FormGroup className="select2-container mb-4" row>
                                      <Label className="col-form-label col-lg-12">
                                      Data
                                      </Label>
                                      <Col md="10">
                                      <select
                                          className="form-control select2"
                                          title="Report"
                                      >
                                          <option value="0">Select</option>
                                          <option value="Data1">Data 1</option>
                                          <option value="Data2">Data 2</option>
                                      </select>
                                      </Col>
                                  </FormGroup> */}
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Summary
                          </Label>
                          <Col lg="10">
                            <Editor
                              id="summaryDesc"
                              name="summaryDesc"
                              editorState={summaryEditorState.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(
                                  newEditorState,
                                  "summaryDesc"
                                )
                              }
                              value={validation.values.summaryDesc || ""}
                            />
                            {validation.touched.summaryDesc &&
                            validation.errors.summaryDesc ? (
                              <FormFeedback type="invalid">
                                {validation.errors.summaryDesc}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col className="mt-3" lg="10">
                            <div className="inner-repeater mb-4">
                              <Label className="col-form-label col-lg-12">
                                Statistics
                              </Label>
                              <div className="inner form-group mb-0 row">
                                <div
                                  className="inner col-lg-10 ml-md-auto"
                                  id="repeater"
                                >
                                  {summaryInputFields.map((item, index) => (
                                    <div
                                      key={index}
                                      id={"summary" + index}
                                      className="mb-3 row align-items-center"
                                    >
                                      <Col md="5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          defaultValue={item.key}
                                          placeholder="Enter Label..."
                                          onChange={e =>
                                            handleOnChange(
                                              "summary",
                                              "key",
                                              index,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col md="5">
                                        <div className="mt-4 mt-md-0">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            defaultValue={item.value}
                                            placeholder="Enter Value..."
                                            onChange={e =>
                                              handleOnChange(
                                                "summary",
                                                "value",
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col md="2">
                                        <div className="mt-2 mt-md-0 d-grid">
                                          <Button
                                            color="primary"
                                            className="inner"
                                            onClick={() => {
                                              handleRemoveFields(
                                                "summary",
                                                index
                                              )
                                            }}
                                            block
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </Col>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <Row className="justify-content-start">
                                <Col lg="10">
                                  <Button
                                    color="success"
                                    className="inner"
                                    onClick={() => {
                                      handleAddFields("summary")
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Impact
                          </Label>
                          <Col lg="10">
                            <Editor
                              id="impactDesc"
                              name="impactDesc"
                              editorState={impactEditorState.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(newEditorState, "impactDesc")
                              }
                              value={validation.values.impactDesc || ""}
                            />
                          </Col>
                          <Col className="mt-3" lg="10">
                            <div className="inner-repeater mb-4">
                              <Label className="col-form-label col-lg-12">
                                Statistics
                              </Label>
                              <div className="inner form-group mb-0 row">
                                <div
                                  className="inner col-lg-10 ml-md-auto"
                                  id="repeater"
                                >
                                  {impactInputFields.map((item, index) => (
                                    <div
                                      key={index}
                                      id={"impact" + index}
                                      className="mb-3 row align-items-center"
                                    >
                                      <Col md="6">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          defaultValue={item.key}
                                          placeholder="Enter Label..."
                                          onChange={e =>
                                            handleOnChange(
                                              "impact",
                                              "key",
                                              index,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col md="4">
                                        <div className="mt-4 mt-md-0">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            defaultValue={item.value}
                                            placeholder="Enter Value..."
                                            onChange={e =>
                                              handleOnChange(
                                                "impact",
                                                "value",
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col md="2">
                                        <div className="mt-2 mt-md-0 d-grid">
                                          <Button
                                            color="primary"
                                            className="inner"
                                            onClick={() => {
                                              handleRemoveFields(
                                                "impact",
                                                index
                                              )
                                            }}
                                            block
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </Col>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <Row className="justify-content-start">
                                <Col lg="10">
                                  <Button
                                    color="success"
                                    className="inner"
                                    onClick={() => {
                                      handleAddFields("impact")
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </FormGroup>
                        {/* <FormGroup className="mb-4" row>
                                      <Label className="col-form-label col-lg-12">
                                          Sustanable Developement Goals
                                      </Label>
                                      <Col lg="10">
                                          <Editor
                                          toolbarClassName="toolbarClassName"
                                          wrapperClassName="wrapperClassName"
                                          editorClassName="editorClassName"
                                          placeholder="Place Your Content Here..."
                                          />
                                      </Col>
                                      <Col md="10">
                                          <div className="mb-3">
                                              <Select
                                                  value={selectedMulti}
                                                  isMulti={true}
                                                  onChange={() => {
                                                  handleMulti();
                                                  }}
                                                  options={optionGroup}
                                                  classNamePrefix="select2-selection"
                                              />
                                          </div>
                                      </Col>
                                      </FormGroup> */}
                        {/* <FormGroup className="mb-4" row>
                                      <Label
                                          htmlFor="desc"
                                          className="col-form-label col-lg-12"
                                      >
                                          Revenue Trend
                                      </Label>
                                      <Col lg="10">
                                          <textarea
                                          className="form-control"
                                          id="desc"
                                          rows="3"
                                          placeholder="Enter Description..."
                                          />
                                      </Col>
                                  </FormGroup> */}
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            User Category
                          </Label>
                          <Col
                            md="10"
                            className={`${
                              validation.touched.targetUser &&
                              validation.errors.targetUser
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <div className={``}>
                              <Select
                                isMulti={true}
                                options={optionGroup1}
                                classNamePrefix="select2-selection"
                                id="targetUser"
                                name="targetUser"
                                type="select"
                                onChange={(option, e) => {
                                  if (
                                    option.length === 1 &&
                                    option.some(
                                      item => item["value"] === "basic"
                                    )
                                  ) {
                                    validation.setFieldValue("access_group", "")
                                    setBasicUserFlag(true)
                                  } else {
                                    setBasicUserFlag(false)
                                  }
                                  validation.handleChange("targetUser", option)
                                  validation.setFieldValue("targetUser", option)
                                }}
                                value={validation.values.targetUser}
                              />
                            </div>
                          </Col>
                          {validation.touched.targetUser &&
                          validation.errors.targetUser ? (
                            <FormFeedback type="invalid">
                              {validation.errors.targetUser}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {!basicUserFlag && (
                          <FormGroup className="select2-container mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              User Access level
                            </Label>
                            <Col
                              md="10"
                              className={`${
                                validation.touched.access_group &&
                                validation.errors.access_group
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <div className={``}>
                                <Select
                                  isMulti={true}
                                  options={userlevelOption}
                                  classNamePrefix="select2-selection"
                                  id="access_group"
                                  name="access_group"
                                  type="select"
                                  onChange={(option, e) => {
                                    //console.log("option", option)
                                    validation.handleChange(
                                      "access_group",
                                      option
                                    )
                                    validation.setFieldValue(
                                      "access_group",
                                      option
                                    )
                                  }}
                                  value={validation.values.access_group}
                                />
                              </div>
                            </Col>
                            {validation.touched.access_group &&
                            validation.errors.access_group ? (
                              <FormFeedback type="invalid">
                                {validation.errors.access_group}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        )}
                        {/* <FormGroup className="select2-container mb-4" row>
                                      <Label className="col-form-label col-lg-12">
                                      Link
                                      </Label>
                                      <Row>
                                          <Col>
                                              <Button color="link" className="btn btn-link ">
                                              Link 1
                                              </Button>
                                          </Col>
                                      </Row>
                                      <div className='spacing'></div>
                                      <Row>
                                          <Col>
                                              <Button color="link" className="btn btn-link ">
                                              Link 2
                                              </Button>
                                          </Col>
                                      </Row>
                                  </FormGroup> */}
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Upload Report
                          </Label>
                          <Col lg="10">
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </Col>
                        </FormGroup>
                        <Row>
                          <Col xs="12">
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={3} key={3} value={3}>
                      <Form onSubmit={validation3.handleSubmit}>
                        {/* <FormGroup className="select2-container mb-4" row>
                                        <Label className="col-form-label col-lg-12">
                                            Language
                                        </Label>
                                        <Col md="10">
                                            <select
                                            className={`form-control select2`}
                                            title="Language"
                                            onChange={validation3.handleChange}
                                            onBlur={validation3.handleBlur}
                                            name="lang_id"
                                            value={validation3.values.lang_id || ""}
                                            placeholder="select language"
                                            >
                                            {language.map((option, index) => (
                                                <option key={index} value={option.id}>
                                                {option.language_name}
                                                </option>
                                            ))}
                                            </select>
                                        </Col>
                                    </FormGroup> */}
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="title"
                            className="col-form-label col-lg-12"
                          >
                            Title
                          </Label>
                          <Col lg="10">
                            <Input
                              id="filename"
                              name="filename"
                              type="text"
                              className="form-control"
                              placeholder="Enter Report Name..."
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              value={validation3.values.filename}
                              invalid={
                                validation3.touched.filename &&
                                validation3.errors.filename
                                  ? true
                                  : false
                              }
                            />
                            {validation3.touched.filename &&
                            validation3.errors.filename ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.filename}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Show on Home Page
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation3.touched.addhome &&
                                validation3.errors.addhome
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Highlights"
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              name="addhome"
                              value={validation3.values.addhome}
                              aria-invalid={
                                validation3.touched.addhome &&
                                validation3.errors.addhome
                                  ? true
                                  : false
                              }
                              placeholder="select Highlight type"
                            >
                              <option value="-1">Select</option>
                              <option value={1}>Yes</option>
                              <option value={0}>No</option>
                            </select>
                            {validation3.touched.addhome &&
                            validation3.errors.addhome ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.addhome}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Report Type
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation3.touched.reporttype &&
                                validation3.errors.reporttype
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Report Type"
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              name="reporttype"
                              value={validation3.values.reporttype || ""}
                              aria-invalid={
                                validation3.touched.reporttype &&
                                validation3.errors.reporttype
                                  ? true
                                  : false
                              }
                              placeholder="select Report Type"
                            >
                              <option value="0">Select</option>
                              <option value="Annual">Annual</option>
                              <option value="Quaterly">Quaterly</option>
                              <option value="Comapny">Comapny</option>
                              <option value="General">General</option>
                            </select>
                            {validation3.touched.reporttype &&
                            validation3.errors.reporttype ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.reporttype}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Company
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation3.touched.companyid &&
                                validation3.errors.companyid
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Report Type"
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              name="companyid"
                              value={validation3.values.companyid || ""}
                              aria-invalid={
                                validation3.touched.companyid &&
                                validation3.errors.companyid
                                  ? true
                                  : false
                              }
                              placeholder="select Report Type"
                              disabled={companyDetailsId ? true : false}
                            >
                              {/* <option value="0">Select</option>
                                          <option value="17">Comapny 1</option>
                                          <option value="16">Comapny 2</option>
                                          <option value="15">Comapny 3</option>
                                          <option value="14">Comapny 4</option> */}
                              <option value="-1">Select Company</option>
                              {companies.map((option, index) => (
                                <option key={index} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            {validation3.touched.companyid &&
                            validation3.errors.companyid ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.companyid}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        {/* <FormGroup className="select2-container mb-4" row>
                                      <Label className="col-form-label col-lg-12">
                                          Country
                                      </Label>
                                      <Col md="10">
                                          <select
                                          className={`form-control select2 ${validation3.touched.country && validation3.errors.country ? 'is-invalid': ''}`}
                                          title="Country"
                                          onChange={validation3.handleChange}
                                          onBlur={validation3.handleBlur}
                                          name="country"
                                          value={validation3.values.country || ""}
                                          aria-invalid={
                                              validation3.touched.country && validation3.errors.country
                                              ? true
                                              : false
                                          }
                                          placeholder="select country"
                                          >
                                          <option value="-1">select country</option>
                                          {optionCountry.map((option, index) => (
                                              <option key={index} value={option.value}>{option.label}</option>
                                          ))}
                                          </select>
                                          {validation3.touched.country &&
                                          validation3.errors.country ? (
                                          <FormFeedback type="invalid">
                                              {validation3.errors.country}
                                          </FormFeedback>
                                          ) : null}
                                      </Col>
                                  </FormGroup> */}
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Chips
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation3.touched.tag &&
                                validation3.errors.tag
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Chips"
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              name="tag"
                              value={validation3.values.tag || ""}
                              aria-invalid={
                                validation3.touched.tag &&
                                validation3.errors.tag
                                  ? true
                                  : false
                              }
                              placeholder="select Chips"
                            >
                              <option value="0">Select</option>
                              <option value="Leading">Leading</option>
                              <option value="Highlight">Highlight</option>
                              <option value="Latest">Latest</option>
                              <option value="In Focus">In Focus</option>
                              <option value="Trending">Trending</option>
                            </select>
                            {validation3.touched.tag &&
                            validation3.errors.tag ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.tag}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="title"
                            className="col-form-label col-lg-12"
                          >
                            Tenure
                          </Label>
                          <Col lg="10">
                            <Input
                              id="tenure"
                              name="tenure"
                              type="text"
                              className="form-control"
                              placeholder="Enter tenure"
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              value={validation3.values.tenure || ""}
                              invalid={
                                validation3.touched.tenure &&
                                validation3.errors.tenure
                                  ? true
                                  : false
                              }
                            />
                            {validation3.touched.tenure &&
                            validation3.errors.tenure ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.tenure}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Report Date
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col className="pl-0">
                                {/* {" "}
                                                  <DatePicker
                                                  className="form-control"
                                                  selected={reportdate}
                                                  id="reportdate"
                                                  name="reportdate"
                                                  placeholder="Please Select Report Date"
                                                  //onChange={validation3.handleChange}
                                                  //onChange={endDateChange}
                                                  onChange={(reportdate) => {
                                                      validation3.setFieldValue('reportdate', new Date(reportdate))
                                                  }}
                                                  onBlur={validation3.handleBlur}
                                                  value={validation3.values.reportdate || ""}
                                                  invalid={
                                                      validation3.touched.reportdate && validation3.errors.reportdate
                                                      ? true
                                                      : false
                                                  }    
                                                  /> */}
                                <Input
                                  className="form-control"
                                  type="date"
                                  defaultValue="2019-08-19"
                                  id="reportdate"
                                  name="reportdate"
                                  onChange={event => {
                                    validation3.setFieldValue(
                                      "reportdate",
                                      new Date(event.target.value)
                                    )
                                  }}
                                  selected={validation3.values.reportdate}
                                  invalid={
                                    validation3.touched.reportdate &&
                                    validation3.errors.reportdate
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                              {validation3.touched.reportdate &&
                              validation3.errors.reportdate ? (
                                <FormFeedback type="invalid">
                                  {validation3.errors.reportdate}
                                </FormFeedback>
                              ) : null}
                            </Row>
                          </Col>
                        </FormGroup>
                        {/* <FormGroup className="select2-container mb-4" row>
                                      <Label className="col-form-label col-lg-12">
                                      Data
                                      </Label>
                                      <Col md="10">
                                      <select
                                          className="form-control select2"
                                          title="Report"
                                      >
                                          <option value="0">Select</option>
                                          <option value="Data1">Data 1</option>
                                          <option value="Data2">Data 2</option>
                                      </select>
                                      </Col>
                                  </FormGroup> */}
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Summary
                          </Label>
                          <Col lg="10">
                            <Editor
                              id="summaryDesc3"
                              name="summaryDesc3"
                              editorState={summaryEditorState3.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(
                                  newEditorState,
                                  "summaryDesc3"
                                )
                              }
                              value={validation3.values.summaryDesc || ""}
                            />
                            {validation3.touched.summaryDesc &&
                            validation3.errors.summaryDesc ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.summaryDesc}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col className="mt-3" lg="10">
                            <div className="inner-repeater mb-4">
                              <Label className="col-form-label col-lg-12">
                                Statistics
                              </Label>
                              <div className="inner form-group mb-0 row">
                                <div
                                  className="inner col-lg-10 ml-md-auto"
                                  id="repeater"
                                >
                                  {summaryInputFields3.map((item, index) => (
                                    <div
                                      key={index}
                                      id={"summary3" + index}
                                      className="mb-3 row align-items-center"
                                    >
                                      <Col md="5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          defaultValue={item.key}
                                          placeholder="Enter Label..."
                                          onChange={e =>
                                            handleOnChange(
                                              "summary3",
                                              "key",
                                              index,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col md="5">
                                        <div className="mt-4 mt-md-0">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            defaultValue={item.value}
                                            placeholder="Enter Value..."
                                            onChange={e =>
                                              handleOnChange(
                                                "summary3",
                                                "value",
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col md="2">
                                        <div className="mt-2 mt-md-0 d-grid">
                                          <Button
                                            color="primary"
                                            className="inner"
                                            onClick={() => {
                                              handleRemoveFields(
                                                "summary3",
                                                index
                                              )
                                            }}
                                            block
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </Col>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <Row className="justify-content-start">
                                <Col lg="10">
                                  <Button
                                    color="success"
                                    className="inner"
                                    onClick={() => {
                                      handleAddFields("summary3")
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Impact
                          </Label>
                          <Col lg="10">
                            <Editor
                              id="impactDesc"
                              name="impactDesc"
                              editorState={impactEditorState3.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(newEditorState, "impactDesc")
                              }
                              value={validation3.values.impactDesc || ""}
                            />
                          </Col>
                          <Col className="mt-3" lg="10">
                            <div className="inner-repeater mb-4">
                              <Label className="col-form-label col-lg-12">
                                Statistics
                              </Label>
                              <div className="inner form-group mb-0 row">
                                <div
                                  className="inner col-lg-10 ml-md-auto"
                                  id="repeater"
                                >
                                  {impactInputFields3.map((item, index) => (
                                    <div
                                      key={index}
                                      id={"impact3" + index}
                                      className="mb-3 row align-items-center"
                                    >
                                      <Col md="6">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          defaultValue={item.key}
                                          placeholder="Enter Label..."
                                          onChange={e =>
                                            handleOnChange(
                                              "impact3",
                                              "key",
                                              index,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col md="4">
                                        <div className="mt-4 mt-md-0">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            defaultValue={item.value}
                                            placeholder="Enter Value..."
                                            onChange={e =>
                                              handleOnChange(
                                                "impact3",
                                                "value",
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col md="2">
                                        <div className="mt-2 mt-md-0 d-grid">
                                          <Button
                                            color="primary"
                                            className="inner"
                                            onClick={() => {
                                              handleRemoveFields(
                                                "impact3",
                                                index
                                              )
                                            }}
                                            block
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </Col>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <Row className="justify-content-start">
                                <Col lg="10">
                                  <Button
                                    color="success"
                                    className="inner"
                                    onClick={() => {
                                      handleAddFields("impact3")
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </FormGroup>
                        {/* <FormGroup className="mb-4" row>
                                      <Label className="col-form-label col-lg-12">
                                          Sustanable Developement Goals
                                      </Label>
                                      <Col lg="10">
                                          <Editor
                                          toolbarClassName="toolbarClassName"
                                          wrapperClassName="wrapperClassName"
                                          editorClassName="editorClassName"
                                          placeholder="Place Your Content Here..."
                                          />
                                      </Col>
                                      <Col md="10">
                                          <div className="mb-3">
                                              <Select
                                                  value={selectedMulti}
                                                  isMulti={true}
                                                  onChange={() => {
                                                  handleMulti();
                                                  }}
                                                  options={optionGroup}
                                                  classNamePrefix="select2-selection"
                                              />
                                          </div>
                                      </Col>
                                      </FormGroup> */}
                        {/* <FormGroup className="mb-4" row>
                                      <Label
                                          htmlFor="desc"
                                          className="col-form-label col-lg-12"
                                      >
                                          Revenue Trend
                                      </Label>
                                      <Col lg="10">
                                          <textarea
                                          className="form-control"
                                          id="desc"
                                          rows="3"
                                          placeholder="Enter Description..."
                                          />
                                      </Col>
                                  </FormGroup> */}
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            User Category
                          </Label>
                          <Col
                            md="10"
                            className={`${
                              validation3.touched.targetUser &&
                              validation3.errors.targetUser
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <div className={``}>
                              <Select
                                isMulti={true}
                                options={optionGroup1}
                                classNamePrefix="select2-selection"
                                id="targetUser"
                                name="targetUser"
                                type="select"
                                onChange={(option, e) => {
                                  if (
                                    option.length === 1 &&
                                    option.some(
                                      item => item["value"] === "basic"
                                    )
                                  ) {
                                    validation3.setFieldValue(
                                      "access_group",
                                      ""
                                    )
                                    setBasicUserFlag(true)
                                  } else {
                                    setBasicUserFlag(false)
                                  }
                                  validation3.handleChange("targetUser", option)
                                  validation3.setFieldValue(
                                    "targetUser",
                                    option
                                  )
                                }}
                                value={validation3.values.targetUser}
                              />
                            </div>
                          </Col>
                          {validation3.touched.targetUser &&
                          validation3.errors.targetUser ? (
                            <FormFeedback type="invalid">
                              {validation3.errors.targetUser}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {!basicUserFlag && (
                          <FormGroup className="select2-container mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              User Access level
                            </Label>
                            <Col
                              md="10"
                              className={`${
                                validation3.touched.access_group &&
                                validation3.errors.access_group
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <div className={``}>
                                <Select
                                  isMulti={true}
                                  options={userlevelOption}
                                  classNamePrefix="select2-selection"
                                  id="access_group"
                                  name="access_group"
                                  type="select"
                                  onChange={(option, e) => {
                                    //console.log("option", option)
                                    validation3.handleChange(
                                      "access_group",
                                      option
                                    )
                                    validation3.setFieldValue(
                                      "access_group",
                                      option
                                    )
                                  }}
                                  value={validation3.values.access_group}
                                />
                              </div>
                            </Col>
                            {validation3.touched.access_group &&
                            validation3.errors.access_group ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.access_group}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        )}
                        {/* <FormGroup className="select2-container mb-4" row>
                                      <Label className="col-form-label col-lg-12">
                                      Link
                                      </Label>
                                      <Row>
                                          <Col>
                                              <Button color="link" className="btn btn-link ">
                                              Link 1
                                              </Button>
                                          </Col>
                                      </Row>
                                      <div className='spacing'></div>
                                      <Row>
                                          <Col>
                                              <Button color="link" className="btn btn-link ">
                                              Link 2
                                              </Button>
                                          </Col>
                                      </Row>
                                  </FormGroup> */}
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Upload Report
                          </Label>
                          <Col lg="10">
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles3.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </Col>
                        </FormGroup>
                        <Row>
                          <Col xs="12">
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddReports.propTypes = {
  reports: PropTypes.array,
  onAddNewReports: PropTypes.func,
}

export default AddReports
