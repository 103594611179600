import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Editor } from "react-draft-wysiwyg"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import * as Yup from "yup"
import { convertToRaw, ContentState, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import {
  addNewHighlights as onAddNewHighlights,
  updateHighlights as onUpdateHighlights,
  getHighlightsById as onGetHighlightsById,
} from "store/highlights/actions"
import { uploadDocument as uploadDocument } from "store/document-upload/actions"
import { getCountry as onGetCountry } from "store/common/actions"
import { toast } from "react-toastify"
import axios from "axios"
import { boolean } from "yup/lib/locale"
import { getAccessToken } from "helpers/jwt-token-access/accessToken"

const initialValues = {
  lang_id: null,
  highlightcode: "",
  imageUrl: "",
  eventType: "",
  subtype: "",
  name: "",
  provience: "",
  pincode: "",
  country: "",
  eventdate: new Date(),
  about: "",
  querydetail: "",
  targetUser: "",
  access_group: null,
  meeturl: "",
  isNew: true,
  venue: "",
  querydetail: "",
  eventtime: "",
  eventendtime: "",
  addhome: "",
  id: null,
}

const urlRegex =
  /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/

const validationSchema = Yup.object({
  name: Yup.string().required("Please Enter name"),
  imageUrl: Yup.mixed().required("Please upload highlights picture"),
  eventType: Yup.string()
    .required("Please Select Event Type")
    .notOneOf(["-1"], "Please Select Event Type"),
  eventdate: Yup.date().required("Please Select Date"),
  eventtime: Yup.string().when("eventType", {
    is: eventType => eventType !== "communication", // Condition: apply validation only if eventType is 'online'
    then: Yup.string().required("Please Select Event Start Time"),
    otherwise: Yup.string(), // No validation rule when condition is not met
  }),
  eventendtime: Yup.string().when("eventType", {
    is: eventType => eventType !== "communication", // Condition: apply validation only if eventType is 'online'
    then: Yup.string().required("Please Select Event End Time"),
    otherwise: Yup.string(), // No validation rule when condition is not met
  }),
  about: Yup.string().required("Please Enter description"),
  targetUser: Yup.array().required("Please select target user"),
  // access_group: Yup.array().required("Please select user access level"),
  meeturl: Yup.string().when("eventType", {
    is: eventType => eventType === "online", // Condition: apply validation only if eventType is 'online'
    then: Yup.string()
      .required("Please Enter meet link")
      .matches(
        urlRegex,
        "Please enter a valid URL starting with http or https"
      ),
    otherwise: Yup.string(), // No validation rule when condition is not met
  }),
  //subtype: Yup.mixed().required('Please select sub type').notOneOf(['-1'], 'Please Select sub type'),
  country: Yup.string().when("eventType", {
    is: eventType => eventType === "offline", // Condition: apply validation only if eventType is 'online'
    then: Yup.string()
      .required("Please Select Country")
      .notOneOf(["-1"], "Please Select Country"),
    otherwise: Yup.string(), // No validation rule when condition is not met
  }),
  venue: Yup.string().when("eventType", {
    is: eventType => eventType === "offline", // Condition: apply validation only if eventType is 'online'
    then: Yup.string().required("Please Enter venue"),
    otherwise: Yup.string(), // No validation rule when condition is not met
  }),
  querydetail: Yup.string().when("eventType", {
    is: eventType => eventType !== "communication", // Condition: apply validation only if eventType is 'online'
    then: Yup.string().required("Please Enter Query details"),
    otherwise: Yup.string(), // No validation rule when condition is not met
  }),
  provience: Yup.string().when("eventType", {
    is: eventType => eventType === "offline", // Condition: apply validation only if eventType is 'online'
    then: Yup.string().required("Provience is required"),
    otherwise: Yup.string(), // No validation rule when condition is not met
  }),
  // pincode: Yup.number().when('eventType', {
  //   is: (eventType) => eventType === 'offline', // Condition: apply validation only if eventType is 'online'
  //   then: Yup.number().required("Please Enter Pincode"),
  //   otherwise: Yup.number() // No validation rule when condition is not met
  // }),
  addhome: Yup.string().required("Please select add to home"),
})

const AddEventHighlights = () => {
  const dispatch = useDispatch()
  // const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date())
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedFiles3, setSelectedFiles3] = useState([])
  const [selectedMulti, setselectedMulti] = useState(null)
  const inpRow = [{ name: "", file: "" }]
  const inpIternityRow = [{ type: "itinerary", title: "", ordernumber: 0 }]
  const inpInstructionRow = [{ type: "steps", title: "", ordernumber: 0 }]
  const inpIternityRow3 = [{ type: "itinerary", title: "", ordernumber: 0 }]
  const inpInstructionRow3 = [{ type: "steps", title: "", ordernumber: 0 }]
  const [iternaryInputFields, setIternaryInputFields] = useState([])
  const [instructionInputFields, setInstructionInputFields] = useState([])
  const [iternaryInputFields3, setIternaryInputFields3] = useState([])
  const [instructionInputFields3, setInstructionInputFields3] = useState([])
  const [inputFields, setinputFields] = useState(inpRow)
  const [countryData, setCountryData] = useState([])
  const [highlightList, setHighlightList] = useState([])
  //const startDateChange = date => {
  //setstartDate(date);
  //};
  function handleAddFields(fieldRef) {
    if (activeTab === 1) {
      if (fieldRef === "itinerary") {
        let temp = inpIternityRow[0]
        temp.ordernumber =
          iternaryInputFields.length <= 0
            ? 1
            : iternaryInputFields[iternaryInputFields.length - 1].ordernumber +
              1
        setIternaryInputFields([...iternaryInputFields, temp])
      } else if (fieldRef === "instruction") {
        let temp = inpInstructionRow[0]
        temp.ordernumber =
          instructionInputFields.length <= 0
            ? 1
            : instructionInputFields[instructionInputFields.length - 1]
                .ordernumber + 1
        setInstructionInputFields([...instructionInputFields, temp])
      }
    } else {
      if (fieldRef === "itinerary3") {
        let temp = inpIternityRow3[0]
        temp.ordernumber =
          iternaryInputFields3.length <= 0
            ? 1
            : iternaryInputFields3[iternaryInputFields3.length - 1]
                .ordernumber + 1
        setIternaryInputFields3([...iternaryInputFields3, temp])
      } else if (fieldRef === "instruction3") {
        let temp = inpInstructionRow3[0]
        temp.ordernumber =
          instructionInputFields3.length <= 0
            ? 1
            : instructionInputFields3[instructionInputFields3.length - 1]
                .ordernumber + 1
        setInstructionInputFields3([...instructionInputFields3, temp])
      }
    }
  }

  function handleRemoveFields(fieldRef, index, entityId) {
    // if(checkDataIsPresent('sectiondata', entityId)) {
    //   setTempEntityData({
    //     'fieldRef': fieldRef,
    //     'index': index
    //   })
    //   dispatch(deleteSectionEntry(entityId, 'sectiondata'))
    // } else {
    removeInputFields(fieldRef, index)
    // }
  }

  const removeInputFields = (fieldRef, index) => {
    if (activeTab === 1) {
      if (fieldRef === "itinerary")
        setIternaryInputFields(removeItemByIndex(iternaryInputFields, index))
      else if (fieldRef === "instruction")
        setInstructionInputFields(
          removeItemByIndex(instructionInputFields, index)
        )
    } else {
      if (fieldRef === "itinerary3")
        setIternaryInputFields3(removeItemByIndex(iternaryInputFields3, index))
      else if (fieldRef === "instruction3")
        setInstructionInputFields3(
          removeItemByIndex(instructionInputFields3, index)
        )
    }
    // else if (fieldRef === "sustainarr") {
    //   validation.handleChange("sdgOptions", index)
    //   validation.setFieldValue("sdgOptions", index)
    // }
  }

  const removeItemByIndex = (array, indexToRemove) => {
    if (indexToRemove < 0 || indexToRemove >= array.length) {
      return array // Return the original array if the index is out of bounds
    }
    const newArray = [...array] // Create a shallow copy of the original array
    newArray.splice(indexToRemove, 1) // Remove one element at the specified index
    return newArray
  }

  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const highlightsId = searchParams.get("highlightcode")
  const language = JSON.parse(localStorage.getItem("language"))
  const [activeTab, setActiveTab] = useState(language[0].id)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedHighlights, setSelectedHighlights] = useState({})
  const [selectedHighlights3, setSelectedHighlights3] = useState({})

  const optionCountry = [
    { value: "AF", label: "Afghanistan" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Algeria" },
    { value: "AS", label: "American Samoa" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarctica" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaijan" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Bahrain" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Belarus" },
    { value: "BE", label: "Belgium" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BT", label: "Bhutan" },
    { value: "BO", label: "Bolivia" },
    { value: "BW", label: "Botswana" },
    { value: "BV", label: "Bouvet Island" },
    { value: "BR", label: "Brazil" },
    { value: "BN", label: "Brunei Darussalam" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "KH", label: "Cambodia" },
    { value: "CM", label: "Cameroon" },
    { value: "CA", label: "Canada" },
    { value: "CV", label: "Cape Verde" },
    { value: "KY", label: "Cayman Islands" },
    { value: "CF", label: "Central African Republic" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Christmas Island" },
    { value: "CC", label: "Cocos (Keeling) Islands" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoros" },
    { value: "CG", label: "Congo" },
    { value: "CK", label: "Cook Islands" },
    { value: "CR", label: "Costa Rica" },
    { value: "CI", label: "Cote d&#39;Ivoire" },
    { value: "HR", label: "Croatia (Hrvatska)" },
    { value: "CU", label: "Cuba" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Czech Republic" },
    { value: "DK", label: "Denmark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominican Republic" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egypt" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Equatorial Guinea" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "ET", label: "Ethiopia" },
    { value: "FK", label: "Falkland Islands (Malvinas)" },
    { value: "FO", label: "Faroe Islands" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finland" },
    { value: "FR", label: "France" },
    { value: "GF", label: "French Guiana" },
    { value: "PF", label: "French Polynesia" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Germany" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Greece" },
    { value: "GL", label: "Greenland" },
    { value: "GD", label: "Grenada" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haiti" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hungary" },
    { value: "IS", label: "Iceland" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IQ", label: "Iraq" },
    { value: "IE", label: "Ireland" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italy" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japan" },
    { value: "JO", label: "Jordan" },
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "KR", label: "Korea, Republic of" },
    { value: "KW", label: "Kuwait" },
    { value: "KG", label: "Kyrgyzstan" },
    { value: "LV", label: "Latvia" },
    { value: "LB", label: "Lebanon" },
    { value: "LS", label: "Lesotho" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libyan Arab Jamahiriya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "MO", label: "Macau" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malaysia" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MQ", label: "Martinique" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexico" },
    { value: "MD", label: "Moldova, Republic of" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolia" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Morocco" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Myanmar" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Netherlands" },
    { value: "AN", label: "Netherlands Antilles" },
    { value: "NC", label: "New Caledonia" },
    { value: "NZ", label: "New Zealand" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Niger" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk Island" },
    { value: "MP", label: "Northern Mariana Islands" },
    { value: "NO", label: "Norway" },
    { value: "OM", label: "Oman" },
    { value: "PW", label: "Palau" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua New Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PH", label: "Philippines" },
    { value: "PN", label: "Pitcairn" },
    { value: "PL", label: "Poland" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Qatar" },
    { value: "RE", label: "Reunion" },
    { value: "RO", label: "Romania" },
    { value: "RU", label: "Russian Federation" },
    { value: "RW", label: "Rwanda" },
    { value: "KN", label: "Saint Kitts and Nevis" },
    { value: "LC", label: "Saint LUCIA" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome and Principe" },
    { value: "SA", label: "Saudi Arabia" },
    { value: "SN", label: "Senegal" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapore" },
    { value: "SK", label: "Slovakia (Slovak Republic)" },
    { value: "SI", label: "Slovenia" },
    { value: "SB", label: "Solomon Islands" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "South Africa" },
    { value: "ES", label: "Spain" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SH", label: "St. Helena" },
    { value: "PM", label: "St. Pierre and Miquelon" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Suriname" },
    { value: "SZ", label: "Swaziland" },
    { value: "SE", label: "Sweden" },
    { value: "CH", label: "Switzerland" },
    { value: "SY", label: "Syrian Arab Republic" },
    { value: "TW", label: "Taiwan, Province of China" },
    { value: "TJ", label: "Tajikistan" },
    { value: "TZ", label: "Tanzania, United Republic of" },
    { value: "TH", label: "Thailand" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad and Tobago" },
    { value: "TN", label: "Tunisia" },
    { value: "TR", label: "Turkey" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TC", label: "Turks and Caicos Islands" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukraine" },
    { value: "AE", label: "United Arab Emirates" },
    { value: "GB", label: "United Kingdom" },
    { value: "US", label: "United States" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Viet Nam" },
    { value: "VG", label: "Virgin Islands (British)" },
    { value: "VI", label: "Virgin Islands (U.S.)" },
    { value: "WF", label: "Wallis and Futuna Islands" },
    { value: "EH", label: "Western Sahara" },
    { value: "YE", label: "Yemen" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" },
  ]

  const optionGroup1 = [
    { label: "Individual Investor", value: "individual_investor" },
    { label: "Advisor", value: "advisor" },
    { label: "Legal Representative", value: "legalrepresent" },
    { label: "Basic", value: "basic" },
  ]

  const userlevelOption = [
    { label: "Intermediate", value: "intermediate" },
    { label: "Advanced", value: "advanced" },
  ]

  const {
    highlights,
    addUpdateSuccess,
    error,
    deleteSuccess,
    highlightsById,
    countryList,
    document,
  } = useSelector(state => ({
    highlights: state.Highlights.highlights,
    error: state.Highlights.error,
    highlightsById: state.Highlights.highlightsById,
    addUpdateSuccess: state.Highlights.addUpdateSuccess,
    deleteSuccess: state.Highlights.deleteSuccess,
    countryList: state.Common.countryList,
    document: state.DocumentUpload.document,
  }))

  useEffect(() => {
    if (error?.status === 401) history.push("/login")
    else if (addUpdateSuccess) {
      toast.success(`Highlight ${isEdit ? "updated" : "added"} successfully`, {
        autoClose: 2000,
      })
      validation.resetForm()
      validation3.resetForm()
      history.push("/templates")
    } else if (
      !addUpdateSuccess &&
      addUpdateSuccess !== null &&
      addUpdateSuccess !== undefined &&
      addUpdateSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
  }, [error, addUpdateSuccess])

  // useEffect(() => {
  //   if ((highlightsId !== null && highlightsId !== undefined) && highlights.length <= 0) {
  //       //call api to get user data;
  //       dispatch(onGetHighlightsById(highlightsId))
  //     //} else if (usersList?.length <= 0 && usersId !== null) {
  //     } else if(highlights.length > 0 && highlightsId !== null) {
  //       //call api to get users data;
  //       setSelectedHighlights(highlights[0])
  //       setIsEdit(true)
  //       assignInitialValues(highlights[0])
  //     }
  // }, [highlightsId, highlights])

  useEffect(() => {
    if (highlightsId !== null && highlightsId !== undefined) {
      //call api to get highlight data;
      let highlightObj = {
        highlightcode: highlightsId,
        lang_id: activeTab === 1 ? language[1].id : language[0].id,
      }
      // console.log(
      //   activeTab,
      //   insightObj,
      //   getNewsById(insightObj),
      //   "News obj and active tab"
      // )
      axios
        .get(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/highlight?highlightcode=" +
            highlightObj.highlightcode +
            "&lang_id=" +
            highlightObj.lang_id,
          { headers: { Authorization: getAccessToken() } }
        )
        .then(res => {
          setHighlightList(res.data.data)
        })
      // dispatch(onGetHighlightsById(highlightsId))
    }
  }, [activeTab])

  useEffect(() => {
    if (highlightList.length === 0) {
      setSelectedOption("")
      console.log("Insiide!!!", selectedOption)
      // console.log(selectedOption,"")
    } else {
      setSelectedOption(highlightList[0].eventType)
    }
  }, [highlightList])

  useEffect(() => {
    if (highlightList.length > 0 && highlightList !== null) {
      highlightList[0]?.lang_id === 1
        ? setSelectedHighlights(highlightList[0])
        : setSelectedHighlights3(highlightList[0])
      setIsEdit(true)
      highlightList[0]?.lang_id === 1
        ? assignInitialValues(highlightList[0])
        : assignInitialValues3(highlightList[0])
    }
  }, [highlightList])

  const assignInitialValues = highlights => {
    console.log(highlights, "Running")
    validation.setFieldValue("lang_id", language[1].id)
    // setActiveTab(highlights?.lang_id)
    //validation.setFieldValue("imageUrl", highlights?.imageUrl);
    validation.setFieldValue("eventType", highlights?.eventType)
    validation.setFieldValue("subtype", highlights?.subtype)
    validation.setFieldValue("name", highlights?.name)
    validation.setFieldValue("eventdate", highlights?.eventdate)
    validation.setFieldValue("about", highlights?.about || "Test")
    validation.setFieldValue("venue", highlights?.venue || "Test")
    validation.setFieldValue("meeturl", highlights?.meeturl)
    validation.setFieldValue("isNew", false)
    validation.setFieldValue("highlightcode", highlights.highlightcode)
    validation.setFieldValue("id", highlights.id)
    validation.setFieldValue("targetUser", highlights.targetUser)
    validation.setFieldValue("access_group", highlights.access_group)
    validation.setFieldValue("country", highlights.country)
    validation.setFieldValue("pincode", highlights.pincode)
    //validation.setFieldValue("subtype", highlights.subtype)
    validation.setFieldValue("querydetail", highlights.querydetail)
    validation.setFieldValue("eventtime", highlights.eventtime)
    validation.setFieldValue("eventendtime", highlights.eventendtime)
    validation.setFieldValue("addhome", highlights.addhome)
    validation.setFieldValue("provience", highlights.provience)

    // access group prefill
    if (
      highlights.access_group !== null &&
      highlights.access_group !== "" &&
      highlights.access_group !== null
    ) {
      let tempArr1 = highlights.access_group.split(",")
      let tempaccess_groupArr = []
      tempArr1.forEach(element => {
        let tempObj = { label: element, value: element }
        tempaccess_groupArr.push(tempObj)
      })
      if (tempaccess_groupArr.length > 0)
        validation.setFieldValue("access_group", tempaccess_groupArr)
    }

    // target user prefill
    if (
      highlights.targetUser !== null &&
      highlights.targetUser !== "" &&
      highlights.targetUser !== null
    ) {
      let tempArr = highlights.targetUser.split(",")
      let tempTargetUserArr = []
      tempArr.forEach(element => {
        let objIndex = findIndexByPropertyValue(optionGroup1, "value", element)
        tempTargetUserArr.push(optionGroup1[objIndex])
      })
      if (tempTargetUserArr.length > 0) {
        validation.setFieldValue("targetUser", tempTargetUserArr)
        if (
          tempTargetUserArr.length === 1 &&
          tempTargetUserArr.some(item => item["value"] === "basic")
        ) {
          validation.setFieldValue("access_group", "")
          // setBasicUserFlag(true);
        } else {
          // setBasicUserFlag(false);
        }
      }
    }

    setDescriptionEditorState(prevState => ({
      ...prevState,
      editorValue: getContentBlock(highlights?.about),
      id: highlights.id,
      highlightsId: highlights?.id,
      value: highlights?.value,
    }))
    setVenueEditorState(prevState => ({
      ...prevState,
      editorValue: getContentBlock(highlights?.venue),
      id: highlights.id,
      highlightsId: highlights?.id,
      value: highlights?.value,
    }))
    /* let tempArrProvience = (highlights.provience).split(',');
    let tempProvienceArr = [];
    tempArrProvience.forEach(element => {
      let tempObj = {"label": element, "value": element}
      tempProvienceArr.push(tempObj);
    });
    validation.setFieldValue("provience", tempProvienceArr); */
    getImageFileFromUrl(highlights?.imageUrl, "Highlight-image").then(file => {
      if (file) {
        let temp = [
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          }),
        ]
        setSelectedFiles(temp)
      }
    })
    validation.setFieldValue("imageUrl", highlights?.imageUrl)
    //}

    setSelectedOption(highlights?.eventType)

    let tempIternityArr = []
    let tempInstructionArr = []
    highlights?.highligthdetail.forEach(item => {
      let tempObj = {}

      tempObj["type"] = item.type
      tempObj["title"] = item.title
      tempObj["ordernumber"] = item.ordernumber
      tempObj["id"] = item.id
      tempObj["lang_id"] = language[1].id
      if (item.type === "itinerary") {
        tempIternityArr.push(tempObj)
      } else if (item.type === "steps") {
        tempInstructionArr.push(tempObj)
      }
    })
    setIternaryInputFields(tempIternityArr)
    setInstructionInputFields(tempInstructionArr)
  }
  const assignInitialValues3 = highlights => {
    validation3.setFieldValue("lang_id", language[0].id)
    // setActiveTab(highlights?.lang_id)
    //validation3.setFieldValue("imageUrl", highlights?.imageUrl);
    validation3.setFieldValue("eventType", highlights?.eventType)
    validation3.setFieldValue("subtype", highlights?.subtype)
    validation3.setFieldValue("name", highlights?.name)
    validation3.setFieldValue("eventdate", highlights?.eventdate)
    validation3.setFieldValue("about", highlights?.about || "Test")
    validation3.setFieldValue("venue", highlights?.venue || "Test")
    validation3.setFieldValue("meeturl", highlights?.meeturl)
    validation3.setFieldValue("isNew", false)
    validation3.setFieldValue("highlightcode", highlights.highlightcode)
    validation3.setFieldValue("id", highlights.id)
    validation3.setFieldValue("targetUser", highlights.targetUser)
    validation3.setFieldValue("access_group", highlights.access_group)
    validation3.setFieldValue("country", highlights.country)
    validation3.setFieldValue("pincode", highlights.pincode)
    //validation3.setFieldValue("subtype", highlights.subtype)
    validation3.setFieldValue("querydetail", highlights.querydetail)
    validation3.setFieldValue("eventtime", highlights.eventtime)
    validation3.setFieldValue("eventendtime", highlights.eventendtime)
    validation3.setFieldValue("addhome", highlights.addhome)
    validation3.setFieldValue("provience", highlights.provience)

    // access group prefill
    if (
      highlights.access_group !== null &&
      highlights.access_group !== "" &&
      highlights.access_group !== null
    ) {
      let tempArr1 = highlights.access_group.split(",")
      let tempaccess_groupArr = []
      tempArr1.forEach(element => {
        let tempObj = { label: element, value: element }
        tempaccess_groupArr.push(tempObj)
      })
      if (tempaccess_groupArr.length > 0)
        validation3.setFieldValue("access_group", tempaccess_groupArr)
    }

    // target user prefill
    if (
      highlights.targetUser !== null &&
      highlights.targetUser !== "" &&
      highlights.targetUser !== null
    ) {
      let tempArr = highlights.targetUser.split(",")
      let tempTargetUserArr = []
      tempArr.forEach(element => {
        let objIndex = findIndexByPropertyValue(optionGroup1, "value", element)
        tempTargetUserArr.push(optionGroup1[objIndex])
      })
      if (tempTargetUserArr.length > 0) {
        validation3.setFieldValue("targetUser", tempTargetUserArr)
        if (
          tempTargetUserArr.length === 1 &&
          tempTargetUserArr.some(item => item["value"] === "basic")
        ) {
          validation3.setFieldValue("access_group", "")
          // setBasicUserFlag(true);
        } else {
          // setBasicUserFlag(false);
        }
      }
    }

    setDescriptionEditorState3(prevState => ({
      ...prevState,
      editorValue: getContentBlock(highlights?.about),
      id: highlights.id,
      highlightsId: highlights?.id,
      value: highlights?.value,
    }))
    setVenueEditorState3(prevState => ({
      ...prevState,
      editorValue: getContentBlock(highlights?.venue),
      id: highlights.id,
      highlightsId: highlights?.id,
      value: highlights?.value,
    }))
    /* let tempArrProvience = (highlights.provience).split(',');
    let tempProvienceArr = [];
    tempArrProvience.forEach(element => {
      let tempObj = {"label": element, "value": element}
      tempProvienceArr.push(tempObj);
    });
    validation3.setFieldValue("provience", tempProvienceArr); */
    getImageFileFromUrl(highlights?.imageUrl, "Highlight-image").then(file => {
      if (file) {
        let temp = [
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          }),
        ]
        setSelectedFiles3(temp)
      }
    })
    validation3.setFieldValue("imageUrl", highlights?.imageUrl)
    //}

    setSelectedOption(highlights?.eventType)

    let tempIternityArr = []
    let tempInstructionArr = []
    highlights?.highligthdetail.forEach(item => {
      let tempObj = {}

      tempObj["type"] = item.type
      tempObj["title"] = item.title
      tempObj["ordernumber"] = item.ordernumber
      tempObj["id"] = item.id
      tempObj["lang_id"] = language[0].id
      if (item.type === "itinerary") {
        tempIternityArr.push(tempObj)
      } else if (item.type === "steps") {
        tempInstructionArr.push(tempObj)
      }
    })
    setIternaryInputFields3(tempIternityArr)
    setInstructionInputFields3(tempInstructionArr)
  }

  const findIndexByPropertyValue = (array, property, value) => {
    return array.findIndex(obj => obj[property] === value)
  }

  const getContentBlock = htmlContent => {
    const contentBlock = htmlToDraft(htmlContent)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const newEditorState = EditorState.createWithContent(contentState)
      return newEditorState
    }
    return ""
  }

  function handleAcceptedFiles(files) {
    dispatch(uploadDocument(files[0]))
    // validation.setFieldValue(
    //   "imageUrl",
    //   "https://epaper.mid-day.com/ePaperImg/md_11032024/?src=direct#epaper_Mumbai/3"
    // )
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    activeTab === 1 ? setSelectedFiles(files) : setSelectedFiles3(files)
  }

  useEffect(() => {
    console.log("document", document)
    if (document !== undefined) {
      activeTab === 1
        ? validation.setFieldValue("imageUrl", document?.fileurl)
        : validation3.setFieldValue("imageUrl", document?.fileurl)
    }
  }, [document])

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [descriptionEditorState, setDescriptionEditorState] = useState(
    EditorState.createEmpty()
  )
  const [descriptionEditorState3, setDescriptionEditorState3] = useState(
    EditorState.createEmpty()
  )
  const handleEditorChange = newEditorState => {
    //setDescriptionEditorState(newEditorState)
    const currentContent = convertToRaw(newEditorState.getCurrentContent())
    const isEmpty = currentContent?.blocks[0]?.text === ""
    // const html = draftToHtml(rawContentState);
    if (activeTab === 1) {
      validation.setFieldValue(
        "about",
        isEmpty ? "" : draftToHtml(currentContent)
      )
      validation.setFieldTouched("about", true)
    } else {
      validation3.setFieldValue(
        "about",
        isEmpty ? "" : draftToHtml(currentContent)
      )
      validation3.setFieldTouched("about", true)
    }
    activeTab === 1
      ? setDescriptionEditorState(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
      : setDescriptionEditorState3(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
  }

  const [venueEditorState, setVenueEditorState] = useState(
    EditorState.createEmpty()
  )
  const [venueEditorState3, setVenueEditorState3] = useState(
    EditorState.createEmpty()
  )
  const handleVenueEditorChange = newEditorState => {
    // setVenueEditorState(newEditorState)
    const currentContent = convertToRaw(newEditorState.getCurrentContent())
    const isEmpty = currentContent?.blocks[0]?.text === ""
    // const html = draftToHtml(rawContentState);
    if (activeTab === 1) {
      validation.setFieldValue(
        "venue",
        isEmpty ? "" : draftToHtml(currentContent)
      )
      validation.setFieldTouched("venue", true)

      setVenueEditorState(prevState => ({
        ...prevState,
        editorValue: newEditorState,
        value: isEmpty ? "" : draftToHtml(currentContent),
      }))
    } else {
      validation3.setFieldValue(
        "venue",
        isEmpty ? "" : draftToHtml(currentContent)
      )
      validation3.setFieldTouched("venue", true)

      setVenueEditorState3(prevState => ({
        ...prevState,
        editorValue: newEditorState,
        value: isEmpty ? "" : draftToHtml(currentContent),
      }))
    }
  }
  /* function handleAddFields(fieldRef) {
    if(fieldRef === 'summary')
      setSummaryInputFields([...summaryInputFields, inpSummaryRow[0]])
    else if(fieldRef === 'impact')
      setImpactInputFields([...impactInputFields, inpImpactRow[0]])
  }

  // Function for Remove Input Fields
  function handleRemoveFields(fieldRef, idx) {
    if(fieldRef === 'summary')
      setSummaryInputFields(removeItemByIndex(summaryInputFields, idx))
    else if(fieldRef === 'impact')
      setImpactInputFields(removeItemByIndex(impactInputFields, idx))
  }

  const removeItemByIndex = (array, indexToRemove) => {
    if (indexToRemove < 0 || indexToRemove >= array.length) {
      return array; // Return the original array if the index is out of bounds
    }
    const newArray = [...array]; // Create a shallow copy of the original array
    newArray.splice(indexToRemove, 1); // Remove one element at the specified index
    return newArray;
  }; */

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      let targetUserString = ""
      if (
        values["targetUser"] !== null &&
        values["targetUser"] !== "" &&
        values["targetUser"].length > 0
      ) {
        console.log(values["targetUser"], "Data access user")
        values["targetUser"].forEach(element => {
          targetUserString +=
            targetUserString === "" ? element.value : `,${element.value}`
        })
      }
      let access_groupString = null
      if (
        values["access_group"] !== null &&
        values["access_group"] !== "" &&
        values["access_group"].length > 0
      ) {
        values["access_group"].forEach(element => {
          access_groupString +=
            access_groupString === "" ? element.value : `,${element.value}`
        })
      }
      let payload = { ...values }
      payload.targetUser = targetUserString
      if (targetUserString === "basic") payload.access_group = null
      else payload.access_group = access_groupString
      /* let provienceString = '';
      values['provience'].forEach(element => {
        provienceString+= provienceString === '' ? element.value : `,${element.value}`
      });
      
      payload.provience = provienceString; */

      let highligthdetail = []
      if (iternaryInputFields.length > 0) {
        let temp = highligthdetail.concat(iternaryInputFields)
        highligthdetail = temp
      }
      if (instructionInputFields.length > 0) {
        let temp = highligthdetail.concat(instructionInputFields)
        highligthdetail = temp
      }
      payload["highligthdetail"] = highligthdetail

      if (isEdit) {
        // console.log("updateHighlightsPayload", payload)
        payload["highlightcode"] = highlightsId
        if (highlightList[0].lang_id !== activeTab) {
          payload.id = null
        } else {
          payload.id = highlightList[0].id
        }
        // update company
        dispatch(onUpdateHighlights(payload))
        // history.push('/templates');
      } else {
        // console.log("newHighlightsPayload", payload)
        // save new customer
        dispatch(onAddNewHighlights(payload))
        validation.resetForm()
        // history.push('/templates');
      }
    },
  })
  const validation3 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      let targetUserString = ""
      if (
        values["targetUser"] !== null &&
        values["targetUser"] !== "" &&
        values["targetUser"].length > 0
      ) {
        values["targetUser"].forEach(element => {
          targetUserString +=
            targetUserString === "" ? element.value : `,${element.value}`
        })
      }
      let access_groupString = null
      if (
        values["access_group"] !== null &&
        values["access_group"] !== "" &&
        values["access_group"].length > 0
      ) {
        values["access_group"].forEach(element => {
          access_groupString +=
            access_groupString === "" ? element.value : `,${element.value}`
        })
      }
      let payload = { ...values }
      payload.targetUser = targetUserString
      if (targetUserString === "basic") payload.access_group = null
      else payload.access_group = access_groupString
      /* let provienceString = '';
      values['provience'].forEach(element => {
        provienceString+= provienceString === '' ? element.value : `,${element.value}`
      });
      
      payload.provience = provienceString; */

      let highligthdetail = []
      if (iternaryInputFields3.length > 0) {
        let temp = highligthdetail.concat(iternaryInputFields3)
        highligthdetail = temp
      }
      if (instructionInputFields3.length > 0) {
        let temp = highligthdetail.concat(instructionInputFields3)
        highligthdetail = temp
      }
      payload["highligthdetail"] = highligthdetail

      if (isEdit) {
        // console.log("updateHighlightsPayload", payload)
        // update company
        payload["highlightcode"] = highlightsId
        if (highlightList[0].lang_id !== activeTab) {
          payload.id = null
        } else {
          payload.id = highlightList[0].id
        }
        dispatch(onUpdateHighlights(payload))
        // history.push('/templates');
      } else {
        // console.log("newHighlightsPayload", payload)
        // save new customer
        dispatch(onAddNewHighlights(payload))
        validation3.resetForm()
        // history.push('/templates');
      }
    },
  })

  useEffect(() => {
    dispatch(onGetCountry())
    if (
      validation?.values["lang_id"] === undefined ||
      validation?.values["lang_id"] === null
    )
      validation.setFieldValue("lang_id", language[1].id)

    if (
      validation3?.values["lang_id"] === undefined ||
      validation3?.values["lang_id"] === null
    )
      validation3.setFieldValue("lang_id", language[0].id)
  }, [])

  // useEffect(() => {
  //   console.log("formik obj", validation)
  // })

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti)
  }

  const [selectedOption, setSelectedOption] = useState("")

  // Event handler to update selected option
  const handleSelectChange = event => {
    //validation.handleChange();
    console.log("Running,", event)
    setSelectedOption(event.target.value)
    activeTab === 1
      ? validation.setFieldValue("eventType", event.target.value)
      : validation3.setFieldValue("eventType", event.target.value)
  }

  const handleOnChange = (secRef, fieldRef, index, value) => {
    /* let tempObj =
      secRef === "itinerary"
        ? iternaryInputFields[index]
        : instructionInputFields[index]
    if (fieldRef === "title") {
      tempObj.title = value
    }
    if (isEdit) tempObj["highlightid"] = selectedHighlights?.id */

    if (activeTab === 1) {
      let tempObj =
        secRef === "itinerary"
          ? iternaryInputFields[index]
          : instructionInputFields[index]
      if (fieldRef === "title") {
        tempObj.title = value
      }
      if (isEdit) tempObj["highlightid"] = selectedHighlights?.id
      if (secRef === "itinerary") {
        setIternaryInputFields(prevItems => {
          const newArray = [...prevItems]
          newArray[index] = tempObj
          return newArray
        })
      } else if (secRef === "instruction") {
        setInstructionInputFields(prevItems => {
          const newArray = [...prevItems]
          newArray[index] = tempObj
          return newArray
        })
      }
    } else {
      let tempObj =
        secRef === "itinerary3"
          ? iternaryInputFields3[index]
          : instructionInputFields3[index]
      if (fieldRef === "title") {
        tempObj.title = value
      }
      if (isEdit) tempObj["highlightid"] = selectedHighlights3?.id
      if (secRef === "itinerary3") {
        setIternaryInputFields3(prevItems => {
          const newArray = [...prevItems]
          newArray[index] = tempObj
          return newArray
        })
      } else if (secRef === "instruction3") {
        setInstructionInputFields3(prevItems => {
          const newArray = [...prevItems]
          newArray[index] = tempObj
          return newArray
        })
      }
    }
  }
  async function getImageFileFromUrl(imageUrl, fileName) {
    try {
      // Fetch image data
      const response = await fetch(imageUrl)
      const blob = await response.blob()

      // Create a File object from the blob
      const file = new File([blob], fileName, { type: blob.type })

      return file
    } catch (error) {
      console.error("Error fetching image:", error)
      return null
    }
  }

  const [pincode, setPincode] = useState("")
  const [provinces, setProvinces] = useState([])
  const [loading, setLoading] = useState(false)
  const provienceData = {
    query: {
      codes: ["421201"],
      country: null,
    },
    results: {
      421201: [
        {
          postal_code: "421201",
          country_code: "IN",
          latitude: "19.21670000",
          longitude: "73.08330000",
          city: "Dombivali",
          state: "Maharashtra",
          city_en: "Dombivali",
          state_en: "Maharashtra",
          state_code: "MH",
          province: "Thane",
          province_code: "TN",
        },
        {
          postal_code: "421201",
          country_code: "IN",
          latitude: "19.21670000",
          longitude: "73.08330000",
          city: "Thakurli",
          state: "Maharashtra",
          city_en: "Thakurli",
          state_en: "Maharashtra",
          state_code: "MH",
          province: "Thane",
          province_code: "TN",
        },
        {
          postal_code: "421201",
          country_code: "IN",
          latitude: "19.21670000",
          longitude: "73.08330000",
          city: "Ramnagar (Thane)",
          state: "Maharashtra",
          city_en: "Ramnagar (Thane)",
          state_en: "Maharashtra",
          state_code: "MH",
          province: "Thane",
          province_code: "TN",
        },
        {
          postal_code: "421201",
          country_code: "IN",
          latitude: "19.21670000",
          longitude: "73.08330000",
          city: "Tilaknagar (Thane)",
          state: "Maharashtra",
          city_en: "Tilaknagar (Thane)",
          state_en: "Maharashtra",
          state_code: "MH",
          province: "Thane",
          province_code: "TN",
        },
      ],
    },
  }
  const handleBlur = async () => {
    setProvinces(provienceData.results[provienceData.query.codes])
    //console.log("provienvce list-->"+provinces)
    validation.setFieldValue(
      "country",
      provienceData.results[provienceData.query.codes][0].country_code
    )
    setLoading(true)
    try {
      // Here you would make your API call to fetch provinces based on the pincode
      // Replace the URL with your actual API endpoint
      const response = await axios.get(
        `http://app.zipcodebase.com/api/v1/search?codes=${pincode}`,
        {
          headers: {
            apikey: "02720a60-07a7-11ef-bc51-ab1625262ad1",
          },
        }
      )
      const data = await response.json()
      //setProvinces(provienceData);
      // console.log("provienvce list-->" + provinces)
    } catch (error) {
      console.error("Error fetching provinces:", error)
    } finally {
      setLoading(false)
    }
  }

  const [provienceList, setProvienceList] = useState([])
  useEffect(() => {
    setProvienceList([])
    validation.setFieldValue("provience", "")
    validation3.setFieldValue("provience", "")
    countryList.forEach(element => {
      if (
        element.countryname === validation.values.country ||
        element.countryname === validation3.values.country
      ) {
        element.region.forEach(item => {
          item.city.forEach(ele => {
            setProvienceList(prevItems => [...prevItems, ele.cityname])
          })
        })
      }
    })
    if (activeTab === 1) {
      if (!validation.values.isNew && validation.values.country !== "") {
        validation.setFieldValue("provience", highlightList[0].provience)
      }
    } else {
      if (!validation3.values.isNew && validation3.values.country !== "") {
        validation3.setFieldValue("provience", highlightList[0].provience)
      }
    }
  }, [validation.values.country, validation3.values.country])

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Highlights</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {isEdit ? (
            <Breadcrumbs title="Templates" breadcrumbItem="Edit Highlights" />
          ) : (
            <Breadcrumbs title="Templates" breadcrumbItem="Add Highlights" />
          )}
          {/* <Row>
                <Col xs="12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between float-right">
                        <Button
                        color="primary"
                        className="btn-rounded "
                        >
                        Add Highlights
                        </Button>
                    </div>    
                </Col>
            </Row> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Add Highlights</CardTitle> */}
                  <Nav pills className="navtab-bg nav-justified mb-2">
                    {language.map((option, index) => (
                      <NavItem key={index} value={option.id}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === option.id,
                          })}
                          onClick={() => {
                            toggle(option.id)
                          }}
                        >
                          {option.language_name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId={1} key={1} value={1}>
                      <Form onSubmit={validation.handleSubmit}>
                        {/* <FormGroup className="select2-container mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Language
                              </Label>
                              <Col md="10">
                                <select
                                  className={`form-control select2`}
                                  title="Language"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  name="lang_id"
                                  value={validation.values.lang_id || ""}
                                  placeholder="select language"
                                >
                                  {language.map((option, index) => (
                                    <option key={index} value={option.id}>
                                      {option.language_name}
                                    </option>
                                  ))}
                                </select>
                              </Col>
                            </FormGroup> */}
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Highlights Type
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation.touched.eventType &&
                                validation.errors.eventType
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Highlights"
                              onChange={event => handleSelectChange(event)}
                              onBlur={validation.handleBlur}
                              name="eventType"
                              value={selectedOption}
                              aria-invalid={
                                validation.touched.eventType &&
                                validation.errors.eventType
                                  ? true
                                  : false
                              }
                              placeholder="select Highlight type"
                            >
                              <option value="-1">Select</option>
                              <option value="online">Online</option>
                              <option value="offline">Offline</option>
                              <option value="communication">
                                Communication
                              </option>
                            </select>
                            {validation.touched.eventType &&
                            validation.errors.eventType ? (
                              <FormFeedback type="invalid">
                                {validation.errors.eventType}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Show on Home Page
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation.touched.addhome &&
                                validation.errors.addhome
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Highlights"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="addhome"
                              value={validation.values.addhome}
                              aria-invalid={
                                validation.touched.addhome &&
                                validation.errors.addhome
                                  ? true
                                  : false
                              }
                              placeholder="select Highlight type"
                            >
                              <option value="-1">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            {validation.touched.addhome &&
                            validation.errors.addhome ? (
                              <FormFeedback type="invalid">
                                {validation.errors.addhome}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        {selectedOption === "communication" ? (
                          <FormGroup className="select2-container mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              Subtype
                            </Label>
                            <Col md="10">
                              <select
                                className="form-control select2"
                                title="Highlights"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                name="subtype"
                                value={validation.values.subtype || ""}
                                aria-invalid={
                                  validation.touched.subtype &&
                                  validation.errors.subtype &&
                                  selectedOption === "communication"
                                    ? true
                                    : false
                                }
                                placeholder="select subtype"
                              >
                                <option value="0">Select</option>
                                <option value="Research">Research</option>
                                <option value="Read">Read</option>
                                <option value="Important">Important</option>
                              </select>
                              {validation.touched.subtype &&
                              validation.errors.subtype &&
                              selectedOption === "communication" ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.subtype}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          </FormGroup>
                        ) : null}
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Photograph
                          </Label>
                          <Col lg="10">
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  className={`dropzone ${
                                    validation.touched.imageUrl &&
                                    validation.errors.imageUrl
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input
                                      name="imageUrl"
                                      onChange={validation.handleChange}
                                      {...getInputProps()}
                                    />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                            {validation.touched.imageUrl &&
                            validation.errors.imageUrl ? (
                              <FormFeedback type="invalid">
                                {validation.errors.imageUrl}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="name"
                            className="col-form-label col-lg-12"
                          >
                            Title
                          </Label>
                          <Col lg="10">
                            <Input
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Enter title..."
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {/*<FormGroup className="select2-container mb-4" row>
                                            <Label className="col-form-label col-lg-12">
                                            Location
                                            </Label>
                                            <Col md="10">
                                            <select
                                                className="form-control select2"
                                                title="location"
                                            >
                                                <option value="0">Select</option>
                                                <option value="Mumbai">Mumbai</option>
                                                <option value="Delhi">Delhi</option>
                                            </select>
                                            </Col>
                                            </FormGroup>*/}
                        {selectedOption === "offline" ? (
                          <></>
                        ) : // <FormGroup className="select2-container mb-4" row>
                        //   <Label className="col-form-label col-lg-12">
                        //       Pincode
                        //   </Label>
                        //   <Col lg="10">
                        //     <Input
                        //       id="pincode"
                        //       name="pincode"
                        //       type="text"
                        //       className="form-control"
                        //       placeholder="Enter pincode..."
                        //       onChange={validation.handleChange}
                        //       onBlur={validation.handleBlur}
                        //       //onChange={(e) => setPincode(e.target.value)}
                        //       // onBlur={handleBlur}
                        //       value={validation.values.pincode}
                        //       invalid={
                        //       validation.touched.pincode && validation.errors.pincode && selectedOption==="offline"
                        //           ? true
                        //           : false
                        //       }
                        //     />
                        //   </Col>
                        //   {validation.touched.pincode && validation.errors.pincode && selectedOption==="offline"? (
                        //       <FormFeedback type="invalid">
                        //       {validation.errors.pincode}
                        //       </FormFeedback>
                        //   ) : null}
                        // </FormGroup>
                        null}
                        {/* {selectedOption==="offline" ? 
                                          <FormGroup className="select2-container mb-4" row>
                                            <Label className="col-form-label col-lg-12">
                                                Province
                                            </Label>
                                            <Col lg="10">
                                              <Input
                                                id="provience"
                                                name="provience"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter provience..."
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.provience}
                                                invalid={
                                                validation.touched.provience && validation.errors.provience && selectedOption==="offline"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </Col>
                                            {validation.touched.provience && validation.errors.provience && selectedOption==="offline"? (
                                                <FormFeedback type="invalid">
                                                {validation.errors.provience}
                                                </FormFeedback>
                                            ) : null}
                                          </FormGroup>
                                        : null} */}
                        {selectedOption === "offline" ? (
                          <>
                            <FormGroup className="select2-container mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Country
                              </Label>
                              <Col md="10">
                                <select
                                  className={`form-control select2 ${
                                    validation.touched.country &&
                                    validation.errors.country &&
                                    selectedOption === "offline"
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  title="Country"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  name="country"
                                  value={validation.values.country || ""}
                                  aria-invalid={
                                    validation.touched.country &&
                                    validation.errors.country &&
                                    selectedOption === "offline"
                                      ? true
                                      : false
                                  }
                                  placeholder="select country"
                                >
                                  <option value="-1">select country</option>
                                  {countryList.map((option, index) => (
                                    <option
                                      key={index}
                                      value={option.countryname}
                                    >
                                      {option.countryname}
                                    </option>
                                  ))}
                                </select>
                                {validation.touched.country &&
                                validation.errors.country &&
                                selectedOption === "offline" ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.country}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            <FormGroup className="select2-container mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Province
                              </Label>
                              {provienceList.length > 0 ? (
                                <>
                                  <Col
                                    md="10"
                                    className={`${
                                      validation.touched.provience &&
                                      validation.errors.provience &&
                                      selectedOption === "offline"
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className={`form-control select2 ${
                                        validation.touched.provience &&
                                        validation.errors.provience &&
                                        selectedOption === "offline"
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      id="provience"
                                      name="provience"
                                      type="select"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.provience}
                                      aria-invalid={
                                        validation.touched.provience &&
                                        validation.errors.provience &&
                                        selectedOption === "offline"
                                          ? true
                                          : false
                                      }
                                      placeholder="select Province"
                                    >
                                      <option value="-1">
                                        Select Province
                                      </option>
                                      {provienceList.map((option, index) => (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                    {validation.touched.provience &&
                                    validation.errors.provience ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.provience}
                                      </FormFeedback>
                                    ) : null}
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col lg="10">
                                    <Input
                                      id="provience"
                                      name="provience"
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter provience..."
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.provience}
                                      invalid={
                                        validation.touched.provience &&
                                        validation.errors.provience &&
                                        selectedOption === "offline"
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.provience &&
                                    validation.errors.provience ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.provience}
                                      </FormFeedback>
                                    ) : null}
                                  </Col>
                                </>
                              )}
                            </FormGroup>
                          </>
                        ) : null}
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Date
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col className="pl-0">
                                {/* {" "}
                                                  <DatePicker
                                                  className="form-control"
                                                  name="eventdate"
                                                  onChange={(eventdate) => {
                                                      validation.setFieldValue('eventdate', new Date(eventdate))
                                                  }}
                                                  selected={validation.values.eventdate}
                                                  invalid={
                                                      validation.touched.eventdate &&
                                                      validation.errors.eventdate
                                                      ? true
                                                      : false
                                                  }
                                                  /> */}
                                <Input
                                  className="form-control"
                                  type="date"
                                  //defaultValue="2019-08-19T13:45:00"
                                  id="eventdate"
                                  name="eventdate"
                                  onChange={event => {
                                    validation.setFieldValue(
                                      "eventdate",
                                      event.target.value
                                    )
                                    //validation.setFieldValue('eventtime', (event.target.value).split("T")[1])
                                  }}
                                  //onChange={validation.handleChange}
                                  selected={validation.values.eventdate}
                                  invalid={
                                    validation.touched.eventdate &&
                                    validation.errors.eventdate
                                      ? true
                                      : false
                                  }
                                  value={validation.values.eventdate}
                                />
                              </Col>
                              {validation.touched.eventdate &&
                              validation.errors.eventdate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.eventdate}
                                </FormFeedback>
                              ) : null}
                            </Row>
                          </Col>
                        </FormGroup>
                        {selectedOption !== "communication" ? (
                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              Start Event Time
                            </Label>
                            <Col lg="10">
                              <Row>
                                <Col className="pl-0">
                                  <Input
                                    className="form-control"
                                    type="time"
                                    defaultValue="13:45:00"
                                    id="eventendtime"
                                    name="eventtime"
                                    onChange={event => {
                                      validation.setFieldValue(
                                        "eventtime",
                                        event.target.value
                                      )
                                    }}
                                    selected={validation.values.eventtime}
                                    invalid={
                                      validation.touched.eventtime &&
                                      validation.errors.eventtime &&
                                      selectedOption !== "communication"
                                        ? true
                                        : false
                                    }
                                    value={validation.values.eventtime}
                                  />
                                </Col>
                                {validation.touched.eventtime &&
                                validation.errors.eventtime &&
                                selectedOption !== "communication" ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.eventtime}
                                  </FormFeedback>
                                ) : null}
                              </Row>
                            </Col>
                          </FormGroup>
                        ) : null}
                        {selectedOption !== "communication" ? (
                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              End Event Time
                            </Label>
                            <Col lg="10">
                              <Row>
                                <Col className="pl-0">
                                  <Input
                                    className="form-control"
                                    type="time"
                                    defaultValue="13:45:00"
                                    id="eventendtime"
                                    name="eventendtime"
                                    onChange={event => {
                                      validation.setFieldValue(
                                        "eventendtime",
                                        event.target.value
                                      )
                                    }}
                                    selected={validation.values.eventendtime}
                                    invalid={
                                      validation.touched.eventendtime &&
                                      validation.errors.eventendtime &&
                                      selectedOption !== "communication"
                                        ? true
                                        : false
                                    }
                                    value={validation.values.eventendtime}
                                  />
                                </Col>
                                {validation.touched.eventendtime &&
                                validation.errors.eventendtime &&
                                selectedOption !== "communication" ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.eventendtime}
                                  </FormFeedback>
                                ) : null}
                              </Row>
                            </Col>
                          </FormGroup>
                        ) : null}
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="desc"
                            className="col-form-label col-lg-12"
                          >
                            {selectedOption !== "communication"
                              ? "About the event"
                              : "Description"}
                          </Label>
                          <Col
                            lg="10"
                            className={`${
                              validation.touched.about &&
                              validation.errors.about
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Place Your Content Here..."
                              id="about"
                              name="about"
                              editorState={descriptionEditorState.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(newEditorState, "about")
                              }
                              value={validation.values.about || ""}
                            />
                          </Col>
                          {validation.touched.about &&
                          validation.errors.about ? (
                            <FormFeedback type="invalid">
                              {validation.errors.about}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {selectedOption !== "communication" ? (
                          <FormGroup className="select2-container mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              Itinerary
                            </Label>
                            <Col md="10">
                              <div className="inner-repeater mb-4">
                                <Label className="col-form-label col-lg-12">
                                  Statistics
                                </Label>
                                <div className="inner form-group mb-0 row">
                                  <div
                                    className="inner col-lg-10 ml-md-auto"
                                    id="repeater"
                                  >
                                    {iternaryInputFields.map((item, index) => (
                                      <div
                                        key={index}
                                        id={"itinerary" + index}
                                        className="mb-3 row align-items-center"
                                      >
                                        <Col md="6">
                                          {/* <input
                                                                    type="text"
                                                                    className="inner form-control"
                                                                    defaultValue={field.name}
                                                                    placeholder="Enter Name..."
                                                                  /> */}
                                          <Input
                                            type="text"
                                            className="form-control"
                                            defaultValue={item.title}
                                            placeholder="Enter Itinerary..."
                                            onChange={e =>
                                              handleOnChange(
                                                "itinerary",
                                                "title",
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col md="2">
                                          <div className="mt-2 mt-md-0 d-grid">
                                            <Button
                                              color="primary"
                                              className="inner"
                                              onClick={() => {
                                                handleRemoveFields(
                                                  "itinerary",
                                                  index,
                                                  item.id
                                                )
                                              }}
                                              block
                                            >
                                              Delete
                                            </Button>
                                          </div>
                                        </Col>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <Row className="justify-content-start">
                                  <Col lg="10">
                                    <Button
                                      color="success"
                                      className="inner"
                                      onClick={() => {
                                        handleAddFields("itinerary")
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </FormGroup>
                        ) : null}
                        {selectedOption !== "communication" ? (
                          <FormGroup className="select2-container mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              Instruction
                            </Label>
                            <Col md="10">
                              <div className="inner-repeater mb-4">
                                <Label className="col-form-label col-lg-12">
                                  Statistics
                                </Label>
                                <div className="inner form-group mb-0 row">
                                  <div
                                    className="inner col-lg-10 ml-md-auto"
                                    id="repeater"
                                  >
                                    {instructionInputFields.map(
                                      (item, index) => (
                                        <div
                                          key={index}
                                          id={"instruction" + index}
                                          className="mb-3 row align-items-center"
                                        >
                                          <Col md="6">
                                            <Input
                                              type="text"
                                              className="form-control"
                                              defaultValue={item.title}
                                              placeholder="Enter Instruction..."
                                              onChange={e =>
                                                handleOnChange(
                                                  "instruction",
                                                  "title",
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col md="2">
                                            <div className="mt-2 mt-md-0 d-grid">
                                              <Button
                                                color="primary"
                                                className="inner"
                                                onClick={() => {
                                                  handleRemoveFields(
                                                    "instruction",
                                                    index,
                                                    item.id
                                                  )
                                                }}
                                                block
                                              >
                                                Delete
                                              </Button>
                                            </div>
                                          </Col>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                <Row className="justify-content-start">
                                  <Col lg="10">
                                    <Button
                                      color="success"
                                      className="inner"
                                      onClick={() => {
                                        handleAddFields("instruction")
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </FormGroup>
                        ) : null}
                        {selectedOption === "offline" ? (
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="desc"
                              className="col-form-label col-lg-12"
                            >
                              Venue
                            </Label>
                            <Col
                              lg="10"
                              className={`${
                                validation.touched.venue &&
                                validation.errors.venue &&
                                selectedOption === "offline"
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                placeholder="Place Your Content Here..."
                                id="venue"
                                name="venue"
                                editorState={venueEditorState.editorValue}
                                //onEditorStateChange={handleVenueEditorChange}
                                onEditorStateChange={newEditorState =>
                                  handleVenueEditorChange(
                                    newEditorState,
                                    "venue"
                                  )
                                }
                                value={validation.values.venue || ""}
                              />
                            </Col>
                            {validation.touched.venue &&
                            validation.errors.venue &&
                            selectedOption === "offline" ? (
                              <FormFeedback type="invalid">
                                {validation.errors.venue}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        ) : null}
                        {selectedOption !== "communication" && (
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="querydetail"
                              className="col-form-label col-lg-12"
                            >
                              For any queries
                            </Label>
                            <Col lg="10">
                              <textarea
                                className="form-control"
                                id="querydetail"
                                rows="2"
                                placeholder="Enter Description..."
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.querydetail} /* 
                                                  invalid={
                                                  validation.touched.querydetail && validation.errors.querydetail
                                                      ? true
                                                      : false
                                                  } */
                              />
                            </Col>
                            {validation.touched.querydetail &&
                            validation.errors.querydetail ? (
                              <FormFeedback type="invalid">
                                {validation.errors.querydetail}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        )}
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            User Category
                          </Label>
                          <Col
                            md="10"
                            className={`${
                              validation.touched.targetUser &&
                              validation.errors.targetUser
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <div className={``}>
                              <Select
                                isMulti={true}
                                options={optionGroup1}
                                classNamePrefix="select2-selection"
                                id="targetUser"
                                name="targetUser"
                                type="select"
                                onChange={(option, e) => {
                                  validation.handleChange("targetUser", option)
                                  validation.setFieldValue("targetUser", option)
                                }}
                                value={validation.values.targetUser}
                              />
                            </div>
                          </Col>
                          {validation.touched.targetUser &&
                          validation.errors.targetUser ? (
                            <FormFeedback type="invalid">
                              {validation.errors.targetUser}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {/* <FormGroup className="select2-container mb-4" row>
                                            <Label className="col-form-label col-lg-12">
                                            User Access level
                                            </Label>
                                            <Col md="10" className={`${validation.touched.access_group && validation.errors.access_group ? "is-invalid" : ""}`}>
                                                <div className={``}>
                                                <Select
                                                    isMulti={true}
                                                    options={userlevelOption}
                                                    classNamePrefix="select2-selection"
                                                    id="access_group"
                                                    name="access_group"
                                                    type="select"
                                                    onChange={(option, e) => {
                                                    //console.log("option", option)
                                                    validation.handleChange("access_group", option)
                                                    validation.setFieldValue("access_group", option)
                                                    }}
                                                    value={validation.values.access_group}
                                                />
                                                </div>
                                            </Col>
                                            {validation.touched.access_group &&
                                            validation.errors.access_group ? (
                                                <FormFeedback type="invalid">
                                                {validation.errors.access_group}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup> */}
                        {selectedOption === "online" ? (
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="title"
                              className="col-form-label col-lg-12"
                            >
                              Link for meeting
                            </Label>
                            <Col
                              lg="10"
                              className={`${
                                validation.touched.meeturl &&
                                validation.errors.meeturl
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <Input
                                id="meeturl"
                                name="meeturl"
                                type="text"
                                className="form-control"
                                placeholder="Enter meeting link..."
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meeturl}
                                invalid={
                                  validation.touched.meeturl &&
                                  validation.errors.meeturl &&
                                  selectedOption === "online"
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            {validation.touched.meeturl &&
                            validation.errors.meeturl &&
                            selectedOption === "online" ? (
                              <FormFeedback type="invalid">
                                {validation.errors.meeturl}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        ) : null}
                        {/* <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="instruction"
                                className="col-form-label col-lg-12"
                              >
                                Instruction to dev
                              </Label>
                              <Col lg="10">
                                <textarea
                                  className="form-control"
                                  id="instruction"
                                  rows="2"
                                  placeholder="Enter Description..."
                                />
                              </Col>
                            </FormGroup> */}
                        <Row>
                          <Col xs="12">
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={3} key={3} value={3}>
                      <Form onSubmit={validation3.handleSubmit}>
                        {/* <FormGroup className="select2-container mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Language
                              </Label>
                              <Col md="10">
                                <select
                                  className={`form-control select2`}
                                  title="Language"
                                  onChange={validation3.handleChange}
                                  onBlur={validation3.handleBlur}
                                  name="lang_id"
                                  value={validation3.values.lang_id || ""}
                                  placeholder="select language"
                                >
                                  {language.map((option, index) => (
                                    <option key={index} value={option.id}>
                                      {option.language_name}
                                    </option>
                                  ))}
                                </select>
                              </Col>
                            </FormGroup> */}
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Highlights Type
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation3.touched.eventType &&
                                validation3.errors.eventType
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Highlights"
                              onChange={event => handleSelectChange(event)}
                              onBlur={validation3.handleBlur}
                              name="eventType"
                              value={selectedOption}
                              aria-invalid={
                                validation3.touched.eventType &&
                                validation3.errors.eventType
                                  ? true
                                  : false
                              }
                              placeholder="select Highlight type"
                            >
                              <option value="-1">Select</option>
                              <option value="online">Online</option>
                              <option value="offline">Offline</option>
                              <option value="communication">
                                Communication
                              </option>
                            </select>
                            {validation3.touched.eventType &&
                            validation3.errors.eventType ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.eventType}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Show on Home Page
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation3.touched.addhome &&
                                validation3.errors.addhome
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Highlights"
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              name="addhome"
                              value={validation3.values.addhome}
                              aria-invalid={
                                validation3.touched.addhome &&
                                validation3.errors.addhome
                                  ? true
                                  : false
                              }
                              placeholder="select Highlight type"
                            >
                              <option value="-1">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            {validation3.touched.addhome &&
                            validation3.errors.addhome ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.addhome}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        {selectedOption === "communication" ? (
                          <FormGroup className="select2-container mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              Subtype
                            </Label>
                            <Col md="10">
                              <select
                                className="form-control select2"
                                title="Highlights"
                                onChange={validation3.handleChange}
                                onBlur={validation3.handleBlur}
                                name="subtype"
                                value={validation3.values.subtype || ""}
                                aria-invalid={
                                  validation3.touched.subtype &&
                                  validation3.errors.subtype &&
                                  selectedOption === "communication"
                                    ? true
                                    : false
                                }
                                placeholder="select subtype"
                              >
                                <option value="0">Select</option>
                                <option value="Research">Research</option>
                                <option value="Read">Read</option>
                                <option value="Important">Important</option>
                              </select>
                              {validation3.touched.subtype &&
                              validation3.errors.subtype &&
                              selectedOption === "communication" ? (
                                <FormFeedback type="invalid">
                                  {validation3.errors.subtype}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          </FormGroup>
                        ) : null}
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Photograph
                          </Label>
                          <Col lg="10">
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  className={`dropzone ${
                                    validation3.touched.imageUrl &&
                                    validation3.errors.imageUrl
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input
                                      name="imageUrl"
                                      onChange={validation3.handleChange}
                                      {...getInputProps()}
                                    />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles3.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                            {validation3.touched.imageUrl &&
                            validation3.errors.imageUrl ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.imageUrl}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="name"
                            className="col-form-label col-lg-12"
                          >
                            Title
                          </Label>
                          <Col lg="10">
                            <Input
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Enter title..."
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              value={validation3.values.name}
                              invalid={
                                validation3.touched.name &&
                                validation3.errors.name
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          {validation3.touched.name &&
                          validation3.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation3.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {/*<FormGroup className="select2-container mb-4" row>
                                            <Label className="col-form-label col-lg-12">
                                            Location
                                            </Label>
                                            <Col md="10">
                                            <select
                                                className="form-control select2"
                                                title="location"
                                            >
                                                <option value="0">Select</option>
                                                <option value="Mumbai">Mumbai</option>
                                                <option value="Delhi">Delhi</option>
                                            </select>
                                            </Col>
                                            </FormGroup>*/}
                        {selectedOption === "offline" ? (
                          <></>
                        ) : // <FormGroup className="select2-container mb-4" row>
                        //   <Label className="col-form-label col-lg-12">
                        //       Pincode
                        //   </Label>
                        //   <Col lg="10">
                        //     <Input
                        //       id="pincode"
                        //       name="pincode"
                        //       type="text"
                        //       className="form-control"
                        //       placeholder="Enter pincode..."
                        //       onChange={validation3.handleChange}
                        //       onBlur={validation3.handleBlur}
                        //       //onChange={(e) => setPincode(e.target.value)}
                        //       // onBlur={handleBlur}
                        //       value={validation3.values.pincode}
                        //       invalid={
                        //       validation3.touched.pincode && validation3.errors.pincode && selectedOption==="offline"
                        //           ? true
                        //           : false
                        //       }
                        //     />
                        //   </Col>
                        //   {validation3.touched.pincode && validation3.errors.pincode && selectedOption==="offline"? (
                        //       <FormFeedback type="invalid">
                        //       {validation3.errors.pincode}
                        //       </FormFeedback>
                        //   ) : null}
                        // </FormGroup>
                        null}
                        {/* {selectedOption==="offline" ? 
                                          <FormGroup className="select2-container mb-4" row>
                                            <Label className="col-form-label col-lg-12">
                                                Province
                                            </Label>
                                            <Col lg="10">
                                              <Input
                                                id="provience"
                                                name="provience"
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter provience..."
                                                onChange={validation3.handleChange}
                                                onBlur={validation3.handleBlur}
                                                value={validation3.values.provience}
                                                invalid={
                                                validation3.touched.provience && validation3.errors.provience && selectedOption==="offline"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </Col>
                                            {validation3.touched.provience && validation3.errors.provience && selectedOption==="offline"? (
                                                <FormFeedback type="invalid">
                                                {validation3.errors.provience}
                                                </FormFeedback>
                                            ) : null}
                                          </FormGroup>
                                        : null} */}
                        {selectedOption === "offline" ? (
                          <>
                            <FormGroup className="select2-container mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Country
                              </Label>
                              <Col md="10">
                                <select
                                  className={`form-control select2 ${
                                    validation3.touched.country &&
                                    validation3.errors.country &&
                                    selectedOption === "offline"
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  title="Country"
                                  onChange={validation3.handleChange}
                                  onBlur={validation3.handleBlur}
                                  name="country"
                                  value={validation3.values.country || ""}
                                  aria-invalid={
                                    validation3.touched.country &&
                                    validation3.errors.country &&
                                    selectedOption === "offline"
                                      ? true
                                      : false
                                  }
                                  placeholder="select country"
                                >
                                  <option value="-1">select country</option>
                                  {countryList.map((option, index) => (
                                    <option
                                      key={index}
                                      value={option.countryname}
                                    >
                                      {option.countryname}
                                    </option>
                                  ))}
                                </select>
                                {validation3.touched.country &&
                                validation3.errors.country &&
                                selectedOption === "offline" ? (
                                  <FormFeedback type="invalid">
                                    {validation3.errors.country}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </FormGroup>
                            <FormGroup className="select2-container mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Province
                              </Label>
                              {provienceList.length > 0 ? (
                                <>
                                  <Col
                                    md="10"
                                    className={`${
                                      validation3.touched.provience &&
                                      validation3.errors.provience &&
                                      selectedOption === "offline"
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className={`form-control select2 ${
                                        validation3.touched.provience &&
                                        validation3.errors.provience &&
                                        selectedOption === "offline"
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      id="provience"
                                      name="provience"
                                      type="select"
                                      onChange={validation3.handleChange}
                                      onBlur={validation3.handleBlur}
                                      value={validation3.values.provience}
                                      aria-invalid={
                                        validation3.touched.provience &&
                                        validation3.errors.provience &&
                                        selectedOption === "offline"
                                          ? true
                                          : false
                                      }
                                      placeholder="select Province"
                                    >
                                      <option value="-1">
                                        Select Province
                                      </option>
                                      {provienceList.map((option, index) => (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                    {validation3.touched.provience &&
                                    validation3.errors.provience ? (
                                      <FormFeedback type="invalid">
                                        {validation3.errors.provience}
                                      </FormFeedback>
                                    ) : null}
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col lg="10">
                                    <Input
                                      id="provience"
                                      name="provience"
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter provience..."
                                      onChange={validation3.handleChange}
                                      onBlur={validation3.handleBlur}
                                      value={validation3.values.provience}
                                      invalid={
                                        validation3.touched.provience &&
                                        validation3.errors.provience &&
                                        selectedOption === "offline"
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation3.touched.provience &&
                                    validation3.errors.provience ? (
                                      <FormFeedback type="invalid">
                                        {validation3.errors.provience}
                                      </FormFeedback>
                                    ) : null}
                                  </Col>
                                </>
                              )}
                            </FormGroup>
                          </>
                        ) : null}
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Date
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col className="pl-0">
                                {/* {" "}
                                                  <DatePicker
                                                  className="form-control"
                                                  name="eventdate"
                                                  onChange={(eventdate) => {
                                                      validation3.setFieldValue('eventdate', new Date(eventdate))
                                                  }}
                                                  selected={validation3.values.eventdate}
                                                  invalid={
                                                      validation3.touched.eventdate &&
                                                      validation3.errors.eventdate
                                                      ? true
                                                      : false
                                                  }
                                                  /> */}
                                <Input
                                  className="form-control"
                                  type="date"
                                  //defaultValue="2019-08-19T13:45:00"
                                  id="eventdate"
                                  name="eventdate"
                                  onChange={event => {
                                    validation3.setFieldValue(
                                      "eventdate",
                                      event.target.value
                                    )
                                    //validation3.setFieldValue('eventtime', (event.target.value).split("T")[1])
                                  }}
                                  //onChange={validation3.handleChange}
                                  selected={validation3.values.eventdate}
                                  invalid={
                                    validation3.touched.eventdate &&
                                    validation3.errors.eventdate
                                      ? true
                                      : false
                                  }
                                  value={validation3.values.eventdate}
                                />
                              </Col>
                              {validation3.touched.eventdate &&
                              validation3.errors.eventdate ? (
                                <FormFeedback type="invalid">
                                  {validation3.errors.eventdate}
                                </FormFeedback>
                              ) : null}
                            </Row>
                          </Col>
                        </FormGroup>
                        {selectedOption !== "communication" ? (
                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              Start Event Time
                            </Label>
                            <Col lg="10">
                              <Row>
                                <Col className="pl-0">
                                  <Input
                                    className="form-control"
                                    type="time"
                                    defaultValue="13:45:00"
                                    id="eventendtime"
                                    name="eventtime"
                                    onChange={event => {
                                      validation3.setFieldValue(
                                        "eventtime",
                                        event.target.value
                                      )
                                    }}
                                    selected={validation3.values.eventtime}
                                    invalid={
                                      validation3.touched.eventtime &&
                                      validation3.errors.eventtime &&
                                      selectedOption !== "communication"
                                        ? true
                                        : false
                                    }
                                    value={validation3.values.eventtime}
                                  />
                                </Col>
                                {validation3.touched.eventtime &&
                                validation3.errors.eventtime &&
                                selectedOption !== "communication" ? (
                                  <FormFeedback type="invalid">
                                    {validation3.errors.eventtime}
                                  </FormFeedback>
                                ) : null}
                              </Row>
                            </Col>
                          </FormGroup>
                        ) : null}
                        {selectedOption !== "communication" ? (
                          <FormGroup className="mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              End Event Time
                            </Label>
                            <Col lg="10">
                              <Row>
                                <Col className="pl-0">
                                  <Input
                                    className="form-control"
                                    type="time"
                                    defaultValue="13:45:00"
                                    id="eventendtime"
                                    name="eventendtime"
                                    onChange={event => {
                                      validation3.setFieldValue(
                                        "eventendtime",
                                        event.target.value
                                      )
                                    }}
                                    selected={validation3.values.eventendtime}
                                    invalid={
                                      validation3.touched.eventendtime &&
                                      validation3.errors.eventendtime &&
                                      selectedOption !== "communication"
                                        ? true
                                        : false
                                    }
                                    value={validation3.values.eventendtime}
                                  />
                                </Col>
                                {validation3.touched.eventendtime &&
                                validation3.errors.eventendtime &&
                                selectedOption !== "communication" ? (
                                  <FormFeedback type="invalid">
                                    {validation3.errors.eventendtime}
                                  </FormFeedback>
                                ) : null}
                              </Row>
                            </Col>
                          </FormGroup>
                        ) : null}
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="desc"
                            className="col-form-label col-lg-12"
                          >
                            {selectedOption !== "communication"
                              ? "About the event"
                              : "Description"}
                          </Label>
                          <Col
                            lg="10"
                            className={`${
                              validation3.touched.about &&
                              validation3.errors.about
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Place Your Content Here..."
                              id="about"
                              name="about"
                              editorState={descriptionEditorState3.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(newEditorState, "about")
                              }
                              value={validation3.values.about || ""}
                            />
                          </Col>
                          {validation3.touched.about &&
                          validation3.errors.about ? (
                            <FormFeedback type="invalid">
                              {validation3.errors.about}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {selectedOption !== "communication" ? (
                          <FormGroup className="select2-container mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              Itinerary
                            </Label>
                            <Col md="10">
                              <div className="inner-repeater mb-4">
                                <Label className="col-form-label col-lg-12">
                                  Statistics
                                </Label>
                                <div className="inner form-group mb-0 row">
                                  <div
                                    className="inner col-lg-10 ml-md-auto"
                                    id="repeater"
                                  >
                                    {iternaryInputFields3.map((item, index) => (
                                      <div
                                        key={index}
                                        id={"itinerary3" + index}
                                        className="mb-3 row align-items-center"
                                      >
                                        <Col md="6">
                                          {/* <input
                                                                    type="text"
                                                                    className="inner form-control"
                                                                    defaultValue={field.name}
                                                                    placeholder="Enter Name..."
                                                                  /> */}
                                          <Input
                                            type="text"
                                            className="form-control"
                                            defaultValue={item.title}
                                            placeholder="Enter Itinerary..."
                                            onChange={e =>
                                              handleOnChange(
                                                "itinerary3",
                                                "title",
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col md="2">
                                          <div className="mt-2 mt-md-0 d-grid">
                                            <Button
                                              color="primary"
                                              className="inner"
                                              onClick={() => {
                                                handleRemoveFields(
                                                  "itinerary3",
                                                  index,
                                                  item.id
                                                )
                                              }}
                                              block
                                            >
                                              Delete
                                            </Button>
                                          </div>
                                        </Col>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <Row className="justify-content-start">
                                  <Col lg="10">
                                    <Button
                                      color="success"
                                      className="inner"
                                      onClick={() => {
                                        handleAddFields("itinerary3")
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </FormGroup>
                        ) : null}
                        {selectedOption !== "communication" ? (
                          <FormGroup className="select2-container mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              Instruction
                            </Label>
                            <Col md="10">
                              <div className="inner-repeater mb-4">
                                <Label className="col-form-label col-lg-12">
                                  Statistics
                                </Label>
                                <div className="inner form-group mb-0 row">
                                  <div
                                    className="inner col-lg-10 ml-md-auto"
                                    id="repeater"
                                  >
                                    {instructionInputFields3.map(
                                      (item, index) => (
                                        <div
                                          key={index}
                                          id={"instruction3" + index}
                                          className="mb-3 row align-items-center"
                                        >
                                          <Col md="6">
                                            <Input
                                              type="text"
                                              className="form-control"
                                              defaultValue={item.title}
                                              placeholder="Enter Instruction..."
                                              onChange={e =>
                                                handleOnChange(
                                                  "instruction3",
                                                  "title",
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col md="2">
                                            <div className="mt-2 mt-md-0 d-grid">
                                              <Button
                                                color="primary"
                                                className="inner"
                                                onClick={() => {
                                                  handleRemoveFields(
                                                    "instruction3",
                                                    index,
                                                    item.id
                                                  )
                                                }}
                                                block
                                              >
                                                Delete
                                              </Button>
                                            </div>
                                          </Col>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                <Row className="justify-content-start">
                                  <Col lg="10">
                                    <Button
                                      color="success"
                                      className="inner"
                                      onClick={() => {
                                        handleAddFields("instruction3")
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </FormGroup>
                        ) : null}
                        {selectedOption === "offline" ? (
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="desc"
                              className="col-form-label col-lg-12"
                            >
                              Venue
                            </Label>
                            <Col
                              lg="10"
                              className={`${
                                validation3.touched.venue &&
                                validation3.errors.venue &&
                                selectedOption === "offline"
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                placeholder="Place Your Content Here..."
                                id="venue"
                                name="venue"
                                editorState={venueEditorState3.editorValue}
                                //onEditorStateChange={handleVenueEditorChange}
                                onEditorStateChange={newEditorState =>
                                  handleVenueEditorChange(
                                    newEditorState,
                                    "venue"
                                  )
                                }
                                value={validation3.values.venue || ""}
                              />
                            </Col>
                            {validation3.touched.venue &&
                            validation3.errors.venue &&
                            selectedOption === "offline" ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.venue}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        ) : null}
                        {selectedOption !== "communication" && (
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="querydetail"
                              className="col-form-label col-lg-12"
                            >
                              For any queries
                            </Label>
                            <Col lg="10">
                              <textarea
                                className="form-control"
                                id="querydetail"
                                rows="2"
                                placeholder="Enter Description..."
                                onChange={validation3.handleChange}
                                onBlur={validation3.handleBlur}
                                value={validation3.values.querydetail} /* 
                                                  invalid={
                                                  validation3.touched.querydetail && validation3.errors.querydetail
                                                      ? true
                                                      : false
                                                  } */
                              />
                            </Col>
                            {validation3.touched.querydetail &&
                            validation3.errors.querydetail ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.querydetail}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        )}
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            User Category
                          </Label>
                          <Col
                            md="10"
                            className={`${
                              validation3.touched.targetUser &&
                              validation3.errors.targetUser
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <div className={``}>
                              <Select
                                isMulti={true}
                                options={optionGroup1}
                                classNamePrefix="select2-selection"
                                id="targetUser"
                                name="targetUser"
                                type="select"
                                onChange={(option, e) => {
                                  validation3.handleChange("targetUser", option)
                                  validation3.setFieldValue(
                                    "targetUser",
                                    option
                                  )
                                }}
                                value={validation3.values.targetUser}
                              />
                            </div>
                          </Col>
                          {validation3.touched.targetUser &&
                          validation3.errors.targetUser ? (
                            <FormFeedback type="invalid">
                              {validation3.errors.targetUser}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {/* <FormGroup className="select2-container mb-4" row>
                                            <Label className="col-form-label col-lg-12">
                                            User Access level
                                            </Label>
                                            <Col md="10" className={`${validation3.touched.access_group && validation3.errors.access_group ? "is-invalid" : ""}`}>
                                                <div className={``}>
                                                <Select
                                                    isMulti={true}
                                                    options={userlevelOption}
                                                    classNamePrefix="select2-selection"
                                                    id="access_group"
                                                    name="access_group"
                                                    type="select"
                                                    onChange={(option, e) => {
                                                    //console.log("option", option)
                                                    validation3.handleChange("access_group", option)
                                                    validation3.setFieldValue("access_group", option)
                                                    }}
                                                    value={validation3.values.access_group}
                                                />
                                                </div>
                                            </Col>
                                            {validation3.touched.access_group &&
                                            validation3.errors.access_group ? (
                                                <FormFeedback type="invalid">
                                                {validation3.errors.access_group}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup> */}
                        {selectedOption === "online" ? (
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="title"
                              className="col-form-label col-lg-12"
                            >
                              Link for meeting
                            </Label>
                            <Col
                              lg="10"
                              className={`${
                                validation3.touched.meeturl &&
                                validation3.errors.meeturl
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <Input
                                id="meeturl"
                                name="meeturl"
                                type="text"
                                className="form-control"
                                placeholder="Enter meeting link..."
                                onChange={validation3.handleChange}
                                onBlur={validation3.handleBlur}
                                value={validation3.values.meeturl}
                                invalid={
                                  validation3.touched.meeturl &&
                                  validation3.errors.meeturl &&
                                  selectedOption === "online"
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            {validation3.touched.meeturl &&
                            validation3.errors.meeturl &&
                            selectedOption === "online" ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.meeturl}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        ) : null}
                        {/* <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="instruction"
                                className="col-form-label col-lg-12"
                              >
                                Instruction to dev
                              </Label>
                              <Col lg="10">
                                <textarea
                                  className="form-control"
                                  id="instruction"
                                  rows="2"
                                  placeholder="Enter Description..."
                                />
                              </Col>
                            </FormGroup> */}
                        <Row>
                          <Col xs="12">
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddEventHighlights
